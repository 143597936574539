import { setImagesList } from '@/states/actions/contents.actions'
import { useAppDispatch } from '@/states/store'
import { MediaInterface } from '@/types'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Slider from 'react-slick'
import { YoutubePlayer } from './YoutubePlayer'

const ImageSlider = ({ images }: { images: MediaInterface[] }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    customPaging: (i) => (
      <div className="slick-dot w-6 h-6 border border-black rounded-full text-center text-xs leading-6">
        {i + 1}
      </div>
    ),
  }

  /**
   * 사진 Zoom 모달 열기
   * @param image
   */
  const openImageZoom = (image) => {
    dispatch(setImagesList(images, image))

    if (
      searchParams.get('scene') &&
      searchParams.get('building') &&
      searchParams.get('history')
    ) {
      navigate(
        `?scene=${searchParams.get('scene')}&building=${searchParams.get(
          'building'
        )}&history=${searchParams.get('history')}&uid=${searchParams.get(
          'uid'
        )}&image=${image._id}`
      )
    } else if (searchParams.get('scene')) {
      navigate(
        `?scene=${searchParams.get('scene')}&uid=${searchParams.get(
          'uid'
        )}&image=${image._id}`
      )
    } else {
      navigate(`?uid=${searchParams.get('uid')}&image=${image._id}`)
    }
  }

  return (
    <div className="modal-slider px-6 pb-52 lg:pt-52 flex items-center justify-center relative overflow-hidden">
      <Slider {...settings}>
        {images.map((image) => (
          <div
            key={image._id}
            className={`${
              image.mediaType === 'URL' ? 'modal-slider-item-video' : ''
            }`}>
            {image.mediaType === 'FILE' ? (
              <div className="modal-slider-image">
                <div
                  className="aspect-1 flex items-center justify-center cursor-pointer px-14"
                  onClick={() => openImageZoom(image)}>
                  <img src={image.file.path} />
                </div>
                {image && image.languageMap && image.languageMap.KO ? (
                  <p className={`mt-5 !text-base leading-lg text-center px-14`}>
                    {image.languageMap.KO.name as string}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {image.mediaType === 'URL' && image.value ? (
              <div className="modal-slider-video">
                <YoutubePlayer item={image} />
                {image && image.languageMap && image.languageMap.KO ? (
                  <p className={`mt-5 leading-lg text-center`}>
                    {image.languageMap.KO.name as string}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ImageSlider
