import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { HistoriesGroup, HistoriesItem, JwBuildingsType } from '@/types'
import ModalContentsHeader from './ModalContentsHeader'
import ModalContentsHistory from './ModalContentsHistory'
import ModalContentsBuildingText from './ModalContentsBuildingText'
import { useSearchParams } from 'react-router-dom'
import ModalHistoriesContentsFooter from './ModalHistoriesContentsFooter'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import ModalRelatedContents from './ModalRelatedContents'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

const bgColor = {
  histories: 'light-yellow',
  episodes: 'gray',
  products: 'gray',
  vArchive: 'gray',
  rndMedia: 'gray',
}

export default function ModalHistoriseGroupContents({
  idx,
  items,
  historiesGroup,
  len,
  currentBuilding,
}: {
  idx: number
  items: HistoriesItem[]
  historiesGroup: HistoriesGroup[]
  len: number
  currentBuilding: JwBuildingsType
}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { contentsState, layoutState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { histories } = contentsState
  const { previousUrl } = layoutState

  // 연혁 그룹
  /* const historiesGroup: HistoriesGroup[] = useMemo(() => {
    const historiesByBuilding = histories.filter(
      (h) => h.data.companyBuilding[0].languageMap.KO === currentBuilding
    )
    const historiesGroup: HistoriesGroup[] = []

    historiesByBuilding.forEach((h) => {
      const year = h.data.startDate.KO?.substring(0, 4) as string
      if (historiesGroup.find((hg) => hg.year === year)) {
        historiesGroup.find((hg) => hg.year === year)?.items.push(h)
      } else {
        historiesGroup.push({
          year,
          items: [h],
        })
      }
    })

    // 정렬
    historiesGroup.sort((a, b) =>
      a.year > b.year ? 1 : b.year > a.year ? -1 : 0
    )

    historiesGroup.forEach((hg) => {
      hg.items.sort((a, b) =>
        // @ts-ignore
        a.data.startDate.KO > b.data.startDate.KO
          ? 1
          : // @ts-ignore
          b.data.startDate.KO > a.data.startDate.KO
          ? -1
          : 0
      )
    })

    return historiesGroup
  }, [histories, currentBuilding]) */

  // State
  const [currentHistory, setCurrentHistory] = useState<number>(0)

  // Effect
  useEffect(() => {
    const scrollContainer = document.getElementById(
      'modal-body-contents-container-' + idx
    )

    scrollContainer?.addEventListener('scroll', (e) => {
      e.preventDefault()

      const posY = scrollContainer.scrollTop
      const offset = 75 + 1
      let targetPosY = 0

      scrollContainer
        .querySelectorAll('.modal-body-contents')
        .forEach((c, cIdx) => {
          // @ts-ignore
          if (c.offsetTop < posY + offset) {
            targetPosY = cIdx
          }
        })

      setCurrentHistory(targetPosY)
      setModalBody()
    })
  }, [])

  /**
   * 본문 영역 크기 조절
   * @returns
   */
  const setModalBody = () => {
    const scrollContainer = document.getElementById(
      `modal-histories-item-${idx}`
    )

    if (!scrollContainer) return

    const header = scrollContainer.querySelector('.modal-header')
    // const headerBg = scrollContainer.getElementById('modal-contents-header-bg')
    const body = scrollContainer.querySelector('.modal-body')
    const bodyContainer = scrollContainer.querySelector(
      '.modal-body-contents-container'
    )
    const footer = scrollContainer.querySelector('.modal-footer')

    if (!header || !body || !bodyContainer || !footer) return

    const headerHeight = header.clientHeight
    const posMoveSize = header.clientHeight * (1 - 0.26)

    if (bodyContainer.scrollTop < posMoveSize) {
      const targetPos = Math.floor(headerHeight - bodyContainer.scrollTop)

      //@ts-ignore
      body.style.top = `${targetPos}px`

      //@ts-ignore
      body.style.height = `calc(100% - ${targetPos}px - ${footer.clientHeight}px)`

      // headerBg.style.bottom = `${body.scrollTop}px`
    }
  }

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = (e) => {
    if (
      e.target.className === 'modal-container' ||
      e.target.className === 'modal-bg'
    ) {
      if (previousUrl) {
        navigate(previousUrl)
        dispatch(getPreviousUrl(''))
      } else {
        navigate(
          searchParams.get('scene') ? `?scene=${searchParams.get('scene')}` : ''
        )
      }
    }
  }

  /**
   * 콘텐츠 변경
   * @param uid
   */
  const changeContents = (history) => {
    const initUid = historiesGroup[history].items[0].uid

    navigate(
      searchParams.get('scene')
        ? `?scene=${searchParams.get('scene')}&building=${searchParams.get(
            'building'
          )}&history=${history}&uid=${initUid}`
        : `?uid=${initUid}`
    )
  }

  return (
    <div className="modal">
      <div className="modal-bg" onClick={(e) => closeModal(e)}></div>
      <div
        className={`modal-container transition-all ${
          Number(searchParams.get('history')) > idx + 1
            ? 'opacity-0'
            : 'opacity-100'
        }`}
        onClick={(e) => closeModal(e)}>
        <div
          className="modal-contents"
          style={{
            // filter: `drop-shadow(8px 0px 12px rgba(0, 0, 0, 0.15))`,
            zIndex: 3200,
          }}>
          {Number(searchParams.get('history')) !== idx ? (
            <div
              className="modal-cover"
              onClick={() => changeContents(idx)}></div>
          ) : (
            <></>
          )}
          <div className="modal-header">
            <ModalContentsHeader
              item={items[0]}
              companyBuilding={
                items[0].data.companyBuilding[0].languageMap
                  .KO as JwBuildingsType
              }
            />
          </div>
          <div id={`modal-body-group-${idx}`} className="modal-body">
            <div
              className={`modal-body-fixed bg-${
                bgColor[items[currentHistory].data.contentsType]
              } ${Number(searchParams.get('history')) === idx ? '' : 'hide'}`}>
              <div className="pt-2.5 pb-2 px-2.5 border-b border-black">
                <ModalContentsBuildingText
                  item={items[currentHistory]}
                  companyBuilding={
                    items[currentHistory].data.companyBuilding[0].languageMap
                      .KO as JwBuildingsType
                  }
                />
              </div>
            </div>
            <div
              id={`modal-body-contents-container-${idx}`}
              className="modal-body-contents-container">
              {items.map((item) => (
                <div
                  id={`modal-body-contents-${item.uid}`}
                  data-id={item.uid}
                  className="modal-body-contents relative"
                  key={item.uid}>
                  {item.data.contentsType === 'histories' ? (
                    <>
                      <ModalContentsHistory
                        item={item as HistoriesItem}
                        inactive={Number(searchParams.get('history')) !== idx}
                      />
                      {(item.data.relationHistories &&
                        item.data.relationHistories.length) ||
                      (item.data.relationEpisodes &&
                        item.data.relationEpisodes.length) ||
                      (item.data.relationProducts &&
                        item.data.relationProducts.length) ? (
                        <div className="related-contents-histories">
                          <ModalRelatedContents item={item} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
          {Number(searchParams.get('history')) === idx ? (
            <div className="modal-footer">
              <ModalHistoriesContentsFooter
                idx={idx}
                len={len}
                item={items[0]}
                curentBuilding={currentBuilding}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
