import { setImagesList } from '@/states/actions/contents.actions'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { MediaInterface } from '@/types'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import WZoom from 'vanilla-js-wheel-zoom/dist/wheel-zoom.min.js'

let wzoom: any = null

export default function ModalImagesZoom() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { images, currentImage } = contentsState

  // State
  const [prevImage, setPrevImage] = useState<MediaInterface | null>(null)
  const [nextImage, setNextImage] = useState<MediaInterface | null>(null)

  // Effect
  useEffect(() => {
    if (currentImage) {
      setTimeout(() => {
        wzoom = WZoom.create('#modal-image', {
          maxScale: 2,
          speed: 30,
          smoothExtinction: 0,
          dragScrollableOptions: {
            smoothExtinction: 0,
          },
        })
      })

      if (images.length > 1) {
        const currentIdx = images.findIndex((i) => i._id === currentImage._id)
        const newPrevImage =
          currentIdx - 1 >= 0
            ? images[currentIdx - 1]
            : images[images.length - 1]
        const newNextImage =
          currentIdx + 1 < images.length ? images[currentIdx + 1] : images[0]

        setPrevImage(newPrevImage)
        setNextImage(newNextImage)
      }
    } else {
      setPrevImage(null)
      setNextImage(null)
    }
  }, [currentImage])

  /**
   * 이미지 모달닫기
   */
  const closeImagesModal = () => {
    dispatch(setImagesList([], null))

    if (
      searchParams.get('scene') &&
      searchParams.get('building') &&
      searchParams.get('history')
    ) {
      navigate(
        `?scene=${searchParams.get('scene')}&building=${searchParams.get(
          'building'
        )}&history=${searchParams.get('history')}&uid=${searchParams.get(
          'uid'
        )}`
      )
    } else if (searchParams.get('scene')) {
      navigate(
        `?scene=${searchParams.get('scene')}&uid=${searchParams.get('uid')}`
      )
    } else {
      navigate(`?uid=${searchParams.get('uid')}`)
    }
  }

  /**
   * Zoom in
   */
  const zoomIn = () => {
    for (let index = 0; index < 15; index++) {
      wzoom?.zoomUp()
    }
  }

  /**
   * Zoom out
   */
  const zoomOut = () => {
    for (let index = 0; index < 15; index++) {
      wzoom?.zoomDown()
    }
  }

  return images && images.length && currentImage ? (
    <div className="modal-images">
      <button
        type="button"
        className="fixed top-3 right-4 btn btn-line close white w-14 bg-center"
        onClick={() => closeImagesModal()}></button>
      <div className="buttons fixed top-3 left-4 space-y-2">
        <button
          type="button"
          className="btn btn-pill btn-pill-inline btn-pill-inline-alt bg-black-alpha plus block"
          onClick={() => zoomIn()}></button>
        <button
          type="button"
          className="btn btn-pill btn-pill-inline btn-pill-inline-alt bg-black-alpha minus block"
          onClick={() => zoomOut()}></button>
      </div>
      {/* {prevImage ? (
        <button
          type="button"
          className="fixed top-1/2 left-4 -mt-6 btn btn-line btn-line-inline-long arrow-left white"
          onClick={() => dispatch(setImagesList(images, prevImage))}></button>
      ) : (
        <></>
      )}
      {nextImage ? (
        <button
          type="button"
          className="fixed top-1/2 right-4 -mt-6 btn btn-line btn-line-inline-long arrow-right white"
          onClick={() => dispatch(setImagesList(images, nextImage))}></button>
      ) : (
        <></>
      )} */}
      <div id="modal-image-container">
        <img
          id="modal-image"
          src={currentImage.file.path}
          alt={currentImage.languageMap.KO?.name}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
