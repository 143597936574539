import {
  ContentsType,
  EpisodesItem,
  HistoriesItem,
  MediaInterface,
  ProductsItem,
  ResourcesItem,
  RndMediaItem,
  vArchiveItem,
} from '@/types'

export enum ActionTypesContents {
  SET_LOADED = 'SET_LOADED', // 로딩 설정
  SET_HISTORIES_LIST = 'SET_HISTORIES_LIST', // 연혁 목록 설정
  SET_EPISODES_LIST = 'SET_EPISODES_LIST', // 에피소드 목록 설정
  SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST', // 제품 목록 설정
  SET_RESOURCES_LIST = 'SET_RESOURCES_LIST', // 미디어 목록 설정
  SET_VARCHIVES_LIST = 'SET_VARCHIVES_LIST', // 버추얼아카이브 목록 설정
  SET_RNDMEDIA_LIST = 'SET_RNDMEDIA_LIST', // R&D (과천 미디어) 목록 설정
  SET_FILTERS = 'SET_FILTERS', // 필터 설정
  SET_IMAGES = 'SET_IMAGES', // 사진 확대보기 목록 설정
}

export type ActionsContents =
  | ContentsSetLoaded
  | ContentsSetHistoriesList
  | ContentsSetEpisodesList
  | ContentsSetProductsList
  | ContentsSetResourcesList
  | ContentsSetvArchivesList
  | ContentsSetRndMediaList
  | ContentsSetFilters
  | ContentsSetImages

export interface ContentsSetLoaded {
  type: ActionTypesContents.SET_LOADED
  payload: {
    loaded: boolean
  }
}

export interface ContentsSetHistoriesList {
  type: ActionTypesContents.SET_HISTORIES_LIST
  payload: {
    list: HistoriesItem[]
  }
}

export interface ContentsSetEpisodesList {
  type: ActionTypesContents.SET_EPISODES_LIST
  payload: {
    list: EpisodesItem[]
  }
}

export interface ContentsSetProductsList {
  type: ActionTypesContents.SET_PRODUCTS_LIST
  payload: {
    list: ProductsItem[]
  }
}

export interface ContentsSetResourcesList {
  type: ActionTypesContents.SET_RESOURCES_LIST
  payload: {
    list: ResourcesItem[]
  }
}

export interface ContentsSetvArchivesList {
  type: ActionTypesContents.SET_VARCHIVES_LIST
  payload: {
    list: vArchiveItem[]
  }
}

export interface ContentsSetRndMediaList {
  type: ActionTypesContents.SET_RNDMEDIA_LIST
  payload: {
    list: RndMediaItem[]
  }
}

export interface ContentsSetFilters {
  type: ActionTypesContents.SET_FILTERS
  payload: {
    filter: {
      keyword: string
      tag: string
      model: ContentsType | ''
    }
  }
}

export interface ContentsSetImages {
  type: ActionTypesContents.SET_IMAGES
  payload: {
    images: MediaInterface[]
    currentImage: MediaInterface
  }
}
