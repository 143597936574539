import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MediaInterface } from '@/types'
import { useAppDispatch } from '@/states/store'
import { setImagesList } from '@/states/actions/contents.actions'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export default function Images({
  images,
  isOrigin,
  noCaption,
  noGutter,
  noZoom,
}: {
  images: MediaInterface[]
  isOrigin?: boolean
  noCaption?: boolean
  noGutter?: boolean
  noZoom?: boolean
}) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * 사진 Zoom 모달 열기
   * @param image
   */
  const openImageZoom = (image) => {
    if (noZoom) return

    dispatch(setImagesList(images, image))

    if (
      searchParams.get('scene') &&
      searchParams.get('building') &&
      searchParams.get('history')
    ) {
      navigate(
        `?scene=${searchParams.get('scene')}&building=${searchParams.get(
          'building'
        )}&history=${searchParams.get('history')}&uid=${searchParams.get(
          'uid'
        )}&image=${image._id}`
      )
    } else if (searchParams.get('scene')) {
      navigate(
        `?scene=${searchParams.get('scene')}&uid=${searchParams.get(
          'uid'
        )}&image=${image._id}`
      )
    } else {
      navigate(`?uid=${searchParams.get('uid')}&image=${image._id}`)
    }
  }

  return (
    <>
      {images.map((image, iIdx) => (
        <li key={iIdx}>
          <div
            className={noZoom ? '' : 'cursor-pointer'}
            onClick={() => openImageZoom(image)}
            style={{
              aspectRatio: `${image.file.meta.width} / ${image.file.meta.height}`,
            }}>
            <LazyLoadImage
              src={
                image.file.thumbnailList &&
                image.file.thumbnailList.length &&
                image.file.thumbnailList.find((t) => t.type === 'LARGE') &&
                !isOrigin
                  ? image.file.thumbnailList.find((t) => t.type === 'LARGE')
                      ?.path
                  : image.file.path
              }
              alt={
                image && image.languageMap && image.languageMap.KO
                  ? (image.languageMap.KO.name as string)
                  : ''
              }
              className="w-full block"
            />
          </div>
          {image && image.languageMap && image.languageMap.KO && !noCaption ? (
            <p className={`pt-3 leading-lg ${noGutter ? 'pr-32' : 'pr-32'}`}>
              {image.languageMap.KO.name as string}
            </p>
          ) : (
            <></>
          )}
        </li>
      ))}
    </>
  )
}
