import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import Layout from '@/components/Layout'
import { RootState } from '@/states/reducers'
import { audioByBuilding, krPanoScenes, vrByBuilding } from '@/configs'
import { HistoriesGroup, JwBuildingsType, SceneInterface } from '@/types'
import { useSearchParams } from 'react-router-dom'
import ModalHistoriesGroup from '@/components/Modals/ModalHistoriesGroup'
import SearchModal from '@/components/Search/SearchModal'
import { useAppDispatch } from '@/states/store'
import {
  toggleArchive,
  toggleBg,
  toggleGuide,
} from '@/states/actions/layout.actions'
import ModalMiniContents from '@/components/Modals/ModalMiniContents'
import Guide from '@/components/Guide'

let krpano = null
const transitionDuration = 1000

export default function Vr() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { vr } = useParams()

  // State (Redux)
  const { contentsState, layouteState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
      layouteState: state.layout,
    }),
    shallowEqual
  )
  const {
    loaded,
    histories,
    importantHistories,
    episodes,
    products,
    resources,
    vArchives,
    rndMedia,
  } = contentsState
  const { archive, guide, bg } = layouteState

  // State
  const [reload, setReload] = useState<boolean>(false)
  const [openReload, setOpenReload] = useState<boolean>(true)
  const [closeReload, setCloseReload] = useState<boolean>(false)
  const [hideReload, setHideReload] = useState<boolean>(false)
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [currentScene, setCurrentScene] = useState<SceneInterface>(
    // @ts-ignore
    krPanoScenes.find((s) => s.building === vr)
  )
  const [playing, setPlaying] = useState<boolean>(false)
  const [playingBg, setPlayingBg] = useState<boolean>(false)
  const [readyPlaying, setReadyPlaying] = useState<boolean>(false)
  const [busyPlaying, setBusyPlaying] = useState<boolean>(true)

  // Memo
  const allContents = useMemo(() => {
    return [
      ...histories,
      ...episodes,
      ...products,
      ...resources,
      ...vArchives,
      ...rndMedia,
    ]
  }, [histories, episodes, products, resources, vArchives, rndMedia])

  const modalContents = useMemo(() => {
    const uid = searchParams.get('uid')
    return uid ? allContents.find((c) => c.uid === uid) : null
  }, [allContents, searchParams.get('uid')])

  // 연혁 그룹
  const historiesGroup = useMemo(() => {
    const targetHistories = archive ? histories : importantHistories

    const historiesByBuilding = targetHistories.filter(
      (h) =>
        h.data.companyBuilding[0].languageMap.KO ===
        searchParams.get('building')
    )
    const historiesGroup: HistoriesGroup[] = []

    historiesByBuilding.forEach((h) => {
      const year = h.data.startDate.KO?.substring(0, 4) as string
      if (historiesGroup.find((hg) => hg.year === year)) {
        historiesGroup.find((hg) => hg.year === year)?.items.push(h)
      } else {
        historiesGroup.push({
          year,
          items: [h],
        })
      }
    })

    // 정렬
    historiesGroup.sort((a, b) =>
      a.year > b.year ? 1 : b.year > a.year ? -1 : 0
    )

    historiesGroup.forEach((hg) => {
      hg.items.sort((a, b) =>
        // @ts-ignore
        a.data.startDate.KO > b.data.startDate.KO
          ? 1
          : // @ts-ignore
          b.data.startDate.KO > a.data.startDate.KO
          ? -1
          : 0
      )
    })

    return historiesGroup
  }, [histories, importantHistories, archive, searchParams.get('building')])

  // Effect
  useEffect(() => {
    if (loaded && vr) {
      initKrPano(vr)
    }
  }, [loaded, vr])

  useEffect(() => {
    if (bg) {
      playAudio()
    } else {
      pauseAudio()
    }

    setPlayingBg(bg)
  }, [bg])

  useEffect(() => {
    if (!modalContents && playing) {
      playAudio()
      setPlaying(false)
    }

    if (modalContents && krpano) {
      // @ts-ignore
      krpano.call(`set(autorotate.enabled,false)`)
    } else if (!modalContents && krpano) {
      // @ts-ignore
      krpano.call(`set(autorotate.enabled,true)`)
    }
  }, [modalContents])

  /**
   * KR pano 시작
   * @param vrBuilding
   */
  const initKrPano = (vrBuilding) => {
    setOpenReload(true)

    setTimeout(() => {
      setReload(true)

      setTimeout(() => {
        setReload(false)

        // KR Pano 겹침 수정
        const oldVr = document.getElementById('krpanoSWFObject')
        if (oldVr) {
          oldVr.remove()
        }

        const taretVrFolder = vrByBuilding[vrBuilding]

        if (taretVrFolder) {
          setTimeout(() => {
            // @ts-ignore
            embedpano({
              xml: `/${taretVrFolder}/tour.xml?v=23033001`,
              target: 'vr-pano',
              html5: 'only',
              mobilescale: 1.0,
              passQueryParameters: false,
              onready: krPanoReady,
            })
          }, transitionDuration)
        } else {
          location.href = '/'
        }
      })
    }, transitionDuration)
  }

  /**
   * KR Pano 준비
   * @param panoObj
   */
  const krPanoReady = (panoObj) => {
    krpano = panoObj

    const initScene = searchParams.get('scene')
    if (initScene) {
      setTimeout(() => {
        const targetScene = krPanoScenes.find((s) => s.building === vr)

        if (targetScene) {
          setCurrentScene(targetScene)
        } else {
          // 층별 메뉴 지정 (6층 ~ 2층)
          if (initScene.toUpperCase().includes('6F')) {
            setCurrentScene(krPanoScenes[0])
          } else if (initScene.toUpperCase().includes('5F')) {
            setCurrentScene(krPanoScenes[1])
          } else if (initScene.toUpperCase().includes('4F')) {
            setCurrentScene(krPanoScenes[2])
          } else if (initScene.toUpperCase().includes('3F')) {
            setCurrentScene(krPanoScenes[3])
          } else if (initScene.toUpperCase().includes('2F')) {
            setCurrentScene(krPanoScenes[4])
          }
        }

        // @ts-ignore
        panoObj.call(`loadscene('${initScene}', null, MERGE, BLEND(1))`)

        // playAudio()
      })
    }

    setTimeout(() => {
      setCloseReload(true)

      setTimeout(() => {
        setHideReload(true)
        setCloseReload(false)

        setTimeout(() => {
          setOpenReload(false)
          setHideReload(false)
        })
      }, transitionDuration)
    })
  }

  /**
   * KR 파노 핫스팟 클릭 핸들러
   * @param e
   */
  const onKrPanoHandle = (e) => {
    e.preventDefault()

    const vrId = (document.getElementById('vr-id') as HTMLInputElement).value
    const vrType = (document.getElementById('vr-type') as HTMLInputElement)
      .value

    if (vrType === 'info') {
      const targetContents = allContents.find((c) => c.data.code.KO === vrId)

      if (targetContents && targetContents.data.contentsType !== 'histories') {
        setPlaying(bg)

        navigate(
          `?scene=${searchParams.get('scene')}&uid=${targetContents.uid}`
        )
      }
      // 연혁 그룹
      else if (targetContents) {
        let historyGroupIdx = 0
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop as string),
        })
        const targetHistories = archive ? histories : importantHistories
        const historiesByBuilding = targetHistories.filter(
          (h) =>
            h.data.companyBuilding[0].languageMap.KO ===
            // @ts-ignore
            targetContents.data.companyBuilding[0].languageMap.KO
        )
        const targetHistoriesGroup: HistoriesGroup[] = []

        if (historiesByBuilding.length === 0) return false

        historiesByBuilding.forEach((h) => {
          const year = h.data.startDate.KO?.substring(0, 4) as string
          if (targetHistoriesGroup.find((hg) => hg.year === year)) {
            targetHistoriesGroup.find((hg) => hg.year === year)?.items.push(h)
          } else {
            targetHistoriesGroup.push({
              year,
              items: [h],
            })
          }
        })

        // 정렬
        targetHistoriesGroup.sort((a, b) =>
          a.year > b.year ? 1 : b.year > a.year ? -1 : 0
        )

        targetHistoriesGroup.forEach((hg) => {
          hg.items.sort((a, b) =>
            // @ts-ignore
            a.data.startDate.KO > b.data.startDate.KO
              ? 1
              : // @ts-ignore
              b.data.startDate.KO > a.data.startDate.KO
              ? -1
              : 0
          )
        })

        targetHistoriesGroup.forEach((hg, hgIdx) => {
          hg.items.forEach((hgi, hgiIdx) => {
            if (hgi.uid === targetContents.uid) {
              historyGroupIdx = hgIdx
            }
          })
        })

        navigate(
          `?scene=${searchParams.get('scene')}&building=${
            // @ts-ignore
            targetContents.data.companyBuilding[0].languageMap.KO
          }&history=${historyGroupIdx}&uid=${targetContents.uid}`
        )
      }
    } else if (vrType === 'scene') {
      if (
        searchParams.get('building') &&
        searchParams.get('history') &&
        searchParams.get('uid')
      ) {
        navigate(
          `?scene=${vrId}&building=${searchParams.get(
            'building'
          )}&history=${searchParams.get('history')}&uid=${searchParams.get(
            'uid'
          )}`
        )
      } else if (searchParams.get('uid')) {
        navigate(`?scene=${vrId}&uid=${searchParams.get('uid')}`)
      } else if (searchParams.get('mini')) {
        navigate(`?scene=${vrId}&mini=${searchParams.get('mini')}`)
      } else {
        navigate(`?scene=${vrId}`)
      }

      const targetScene = krPanoScenes.find(
        (s) => s.scene.toLowerCase() === vrId.toLowerCase()
      )
      if (targetScene) {
        setCurrentScene(targetScene)
      } else {
        const initScene = vrId

        // 층별 메뉴 지정 (6층 ~ 2층)
        if (initScene.toUpperCase().includes('6F')) {
          setCurrentScene(krPanoScenes[0])
        } else if (initScene.toUpperCase().includes('5F')) {
          setCurrentScene(krPanoScenes[1])
        } else if (initScene.toUpperCase().includes('4F')) {
          setCurrentScene(krPanoScenes[2])
        } else if (initScene.toUpperCase().includes('3F')) {
          setCurrentScene(krPanoScenes[3])
        } else if (initScene.toUpperCase().includes('2F')) {
          setCurrentScene(krPanoScenes[4])
        }
      }
    } else if (vrType === 'mini') {
      navigate(`?scene=${searchParams.get('scene')}&mini=${vrId}`)
    } else if (vrType === 'vr') {
      // navigate(`/vr/${vrId}`)
      // location.href = `/vr/${vrId}`

      const targetScene = krPanoScenes.find((s) => s.building === vrId)

      if (targetScene) {
        changeScene(targetScene)
      } else {
        location.href = `/vr/${vrId}`
      }
    } else if (vrType === 'openArchive') {
      dispatch(toggleArchive(true))
    }
  }

  /**
   * Scene 이동
   * @param scene
   */
  const changeScene = (scene: SceneInterface) => {
    if (hideReload) return

    pauseAudio()
    setCurrentScene(scene)
    setShowMenu(false)
    setOpenReload(true)

    setTimeout(() => {
      navigate(`/vr/${scene.building}?scene=${scene.scene}`)

      if (playingBg) {
        playAudio()
      }
    })
  }

  /**
   * 배경음악 켜기/끄기
   * @returns
   */
  const toggleAudio = () => {
    if (busyPlaying || !readyPlaying) return

    setBusyPlaying(true)
    dispatch(toggleBg(!bg))

    setTimeout(() => {
      setBusyPlaying(false)
    }, 2500)

    /* try {
      dispatch(toggleBg(true))

      setTimeout(() => {
        const audio = document.getElementById('audio') as HTMLAudioElement

        if (!audio) return

        !bg ? audio.pause() : audio.play()
      })
    } catch (e) {
      console.log(e)
    } */
  }

  /**
   * 배경음악 켜기
   * @returns
   */
  const playAudio = () => {
    try {
      dispatch(toggleBg(true))

      setTimeout(async () => {
        const audio = document.getElementById('audio') as HTMLAudioElement

        if (!audio) return

        await new Promise((r) => setTimeout(r, 2000))
        audio &&
          audio
            .play()
            .then(() => {
              setBusyPlaying(false)
            })
            .catch((e) => {
              // setAudioError(e)
            })
      })
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * 배경음악 켜기
   * @returns
   */
  const pauseAudio = () => {
    try {
      const audio = document.getElementById('audio') as HTMLAudioElement

      if (!audio) return

      audio.pause()
      dispatch(toggleBg(false))
      setReadyPlaying(false)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Layout>
      <div className="vr-container">
        <div
          className="fixed top-3 md:top-2 left-0 md:left-1 z-10 logo logo-jw-voyage cursor-pointer"
          onClick={() => navigate('/')}></div>
        <div className="relative md:fixed top-3 right-4 z-10 flex space-x-0">
          <div className="fixed top-3 left-3 hidden md:block md:relative md:top-auto md:left-auto">
            <div
              className="btn btn-pill btn-pill-sm md-icon-only bg-white w-max text-lg font-medium px-5"
              onClick={() => dispatch(toggleArchive(true))}>
              JW 아카이브
            </div>
          </div>
          <div
            className={`vr-menu fixed top-3 right-3 md:relative md:top-auto md:right-auto ${
              showMenu ? 'show' : ''
            }`}>
            <div
              className="vr-menu-bg"
              onClick={() => setShowMenu(false)}></div>
            <div className="vr-menu-container">
              <div
                className={`vr-menu-item btn btn-pill btn-pill-sm md-icon-only bg-white w-max ${
                  showMenu ? 'floor mobile-close' : 'floor'
                }`}
                onClick={() => setShowMenu(!showMenu)}
                style={{
                  minWidth: '17.5rem',
                }}
                dangerouslySetInnerHTML={{
                  __html: currentScene.label.replace(/\n/g, '<br />'),
                }}></div>
              <div
                className={`vr-menu-list absolute right-0 lg:right-auto lg:left-0 ${
                  showMenu ? 'show' : ''
                }`}>
                <ul className="flex flex-col items-end lg:items-start">
                  {krPanoScenes.map((scene, sIdx) => (
                    <li
                      key={sIdx}
                      className={`vr-menu-item btn btn-pill bg-white w-max cursor-pointer ${
                        currentScene.scene === scene.scene ? 'active' : ''
                      }`}
                      onPointerDown={() => changeScene(scene)}
                      dangerouslySetInnerHTML={{
                        __html: scene.label.replace(/\n/g, '<br />'),
                      }}></li>
                  ))}

                  <li
                    className="vr-menu-item vr-menu-item-mobile btn btn-pill bg-white w-max cursor-pointer"
                    onClick={() => {
                      setShowMenu(false)
                      dispatch(toggleArchive(true))
                    }}>
                    <div className="arrow arrow-long extra"></div>
                    <div className="name long ml-1">JW 아카이브</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {!reload ? <div id="vr-pano" /> : <></>}
        <form id="vr-form" onSubmit={onKrPanoHandle}>
          <input id="vr-type" type="hidden" />
          <input id="vr-id" type="hidden" />
          <button id="btn-callback" type="submit" />
        </form>
      </div>
      {searchParams.get('building') && searchParams.get('history') ? (
        <ModalHistoriesGroup
          show={!!searchParams.get('history')}
          building={searchParams.get('building') as JwBuildingsType}
          historiesGroup={historiesGroup}
        />
      ) : (
        <></>
      )}
      {/* {krPanoScenes.map((scene) => (
        <ModalHistoriesGroup
          key={scene.building}
          show={
            !!searchParams.get('building') &&
            scene.building === searchParams.get('building')
          }
          building={searchParams.get('building') as JwBuildingsType}
          historiesGroup={historiesGroup}
        />
      ))} */}
      <SearchModal />
      {searchParams.get('mini') ? (
        <ModalMiniContents id={searchParams.get('mini') as string} />
      ) : (
        <></>
      )}
      {bg ? (
        <audio
          id="audio"
          src={audioByBuilding[currentScene.building]}
          preload="auto"
          autoPlay
          onCanPlay={() => {
            setReadyPlaying(true)
          }}
          loop></audio>
      ) : (
        <></>
      )}
      <button
        type="button"
        className={`fixed bottom-[4.6rem] right-[18px] btn btn-sound ${
          bg ? 'off' : 'on'
        }`}
        onClick={() => toggleAudio()}
      />
      <button
        type="button"
        className={`fixed bottom-[7.1875rem] right-[18px] btn btn-guide`}
        onClick={() => dispatch(toggleGuide(true))}
      />
      <Guide />
      <div
        className={`vr-reload ${openReload ? 'open' : ''} ${
          closeReload ? 'close' : ''
        } ${hideReload ? 'hide' : ''}`}>
        <div className="vr-reload-logo"></div>
      </div>
    </Layout>
  )
}
