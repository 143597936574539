import React from 'react'
import { setFilters } from '@/states/actions/contents.actions'
import { useAppDispatch } from '@/states/store'
import { useNavigate } from 'react-router'

export default function SearchNotFound() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const resetSearch = () => {
    dispatch(setFilters('', '', ''))
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="search-not-found pt-20 text-center">
        <p className="mb-10 color-gray">검색 결과를 찾을 수 없습니다</p>
        <div className="flex justify-center">
          <button
            type="button"
            className="px-3 py-2 border border-black rounded-2xl"
            onClick={() => resetSearch()}>
            검색 초기화
          </button>
        </div>
      </div>
    </div>
  )
}
