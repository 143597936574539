import React from 'react'

const MiniContents2_3 = () => {
  return (
    <div className="">
      <div className="md:pr-12">
        <h3 className="font-medium leading-lg mb-5">
          일관작업이 가능해진 수액개발의 메카
        </h3>
        <p className="leading-lg mb-10">
          본관 전면 길이가 횡으로 100m나 되는 2층 구조의 흰색 건물이고 공장터가
          논밭이 펼쳐진 벌판 한가운데 있어, 차량으로 인근도로를 지나다 보면
          &apos;언덕 위의 하얀 집&apos;이 금방 눈에 들어왔다.
          <br />
          <br />
          공장 건물 뒤편으로 수령 100년을 넘긴 노송 숲이 조성돼 수목원 분위기를
          자아내고, 여름이면 공장 본관 앞에 설치한 &apos;5% 포도당&apos; 수액제
          형상의 분수대가 물을 뿜어내는 광경이 이채로웠다.
        </p>
      </div>

      <div className="space-y-3">
        <img
          src={'/assets/img-mini-4-1.png'}
          alt="두번째 사옥인 하월곡동 사옥의 전경"
          className="w-full block"
        />
        <p className="text-xs font-medium">
          두번째 사옥인 하월곡동 사옥의 전경
        </p>
      </div>
    </div>
  )
}

export default MiniContents2_3
