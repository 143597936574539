import React from 'react'

const MiniContents2_4 = () => {
  return (
    <div className="">
      <div className="space-y-3">
        <img
          src={'/assets/img-mini-5-1.png'}
          alt="70년대 당시 주력제품인 피바록신 구호가 적혀있는 하월곡동 사옥"
          className="w-full block"
        />
        <p className="text-xs font-medium">
          70년대 당시 주력제품인 피바록신 구호가 적혀있는 하월곡동 사옥
        </p>
      </div>
    </div>
  )
}

export default MiniContents2_4
