import { miniContentsList } from '@/configs'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import MiniContents1 from '../MiniContents/MiniContents1'
import MiniContents2_1 from '../MiniContents/MiniContents2_1'
import MiniContents2_2 from '../MiniContents/MiniContents2_2'
import MiniContents2_3 from '../MiniContents/MiniContents2_3'
import MiniContents2_4 from '../MiniContents/MiniContents2_4'
import MiniContents3 from '../MiniContents/MiniContents3'

const ModalMiniContents = ({ id }: { id: string }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [open, setOpen] = useState<boolean>(false)

  // Memo
  const miniContents = useMemo(() => {
    return miniContentsList.find((m) => m.id === id)
  }, [id])

  // Effect
  useEffect(() => {
    setOpen(true)
  }, [])

  /**
   * 모달 닫기
   */
  const closeModal = () => {
    navigate(`?scene=${searchParams.get('scene')}`)
  }

  return miniContents ? (
    <div
      className={`modal-mini-contents ${miniContents.dir} ${
        open ? 'open' : ''
      }`}>
      <div className="modal-bg" onClick={() => closeModal()}></div>
      <div className="modal-contents">
        <div className="sticky top-8 mr-6 flex justify-end mb-10">
          <button
            type="button"
            className="btn btn-line close w-10 h-10"
            onClick={() => closeModal()}
          />
        </div>
        <div className="modal-body py-6 px-8">
          {id === '충무로-1' ? <MiniContents1 /> : <></>}
          {id === '하월곡-1' ? (
            <div className="space-y-20">
              <MiniContents2_3 />
              <MiniContents2_4 />
              <MiniContents2_1 />
              <MiniContents2_2 />
            </div>
          ) : (
            <></>
          )}
          {/* {id === '하월곡-2' ? <MiniContents2_2 /> : <></>}
          {id === '하월곡-3' ? <MiniContents2_3 /> : <></>}
          {id === '하월곡-4' ? <MiniContents2_4 /> : <></>} */}
          {id === '서초-1' ? <MiniContents3 /> : <></>}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ModalMiniContents
