import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import axios from 'axios'
import Lottie from 'react-lottie'
import { RootState } from '@/states/reducers'
import { useLocation, useNavigate } from 'react-router'
import {
  getContentsDataList,
  setEpisodesList,
  setHistoriesList,
  setImagesList,
  setLoaded,
  setProductsList,
  setResourcesList,
  setRndMediaList,
  setvArchivesList,
} from '@/states/actions/contents.actions'
import { DATA_CHUNK_SIZE, modelKey, vArchiveVideos } from '@/configs'
import { useAppDispatch } from '@/states/store'
import { useSearchParams } from 'react-router-dom'
import ModalContents from './Modals/ModalContents'
import {
  EpisodesItem,
  HistoriesItem,
  ProductsItem,
  ResourcesItem,
  RndMediaItem,
  vArchiveItem,
} from '@/types'
import ModalResource from './Modals/ModalResource'
import ModalImagesZoom from './Modals/ModalImagesZoom'
import { getPreviousUrl } from '@/states/actions/layout.actions'

type LayoutProps = {
  full?: boolean | undefined
  scroll?: boolean | undefined
  noFooter?: boolean | undefined
  children?: ReactNode
}

const Layout = ({ full, scroll, noFooter, children }: LayoutProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const {
    loaded,
    histories,
    episodes,
    products,
    resources,
    vArchives,
    rndMedia,
  } = contentsState

  // Memo
  const allContents = useMemo(() => {
    return [
      ...histories,
      ...episodes,
      ...products,
      ...resources,
      ...vArchives,
      ...rndMedia,
    ]
  }, [histories, episodes, products, resources, vArchives, rndMedia])

  const modalContents = useMemo(() => {
    const uid = searchParams.get('uid')
    return uid ? allContents.find((c) => c.uid === uid) : null
  }, [allContents, searchParams.get('uid')])

  // Effect
  useEffect(() => {
    document.body.style.overflow = searchParams.get('uid') ? 'hidden' : 'auto'

    if (!searchParams.get('uid')) {
      dispatch(getPreviousUrl(''))
    }
  }, [searchParams.get('uid')])

  useEffect(() => {
    if (!loaded) {
      const loadData = async () => {
        await fetchContentsData(modelKey.histories, function (res) {
          dispatch(setHistoriesList(res))
        })
        await fetchContentsData(modelKey.episodes, function (res) {
          dispatch(setEpisodesList(res))
        })
        await fetchContentsData(modelKey.products, function (res) {
          dispatch(setProductsList(res))
        })
        await fetchContentsData(modelKey.resources, function (res) {
          dispatch(setResourcesList(res))
        })
        await fetchContentsData(modelKey.vArchive, function (res) {
          dispatch(setvArchivesList(res))
        })
        await fetchContentsData(modelKey.rndMedia, function (res) {
          dispatch(setRndMediaList(res))
        })

        await setTimeout(() => {
          dispatch(setLoaded(true))
        })
      }

      loadData()
    } else {
      document.onkeydown = function (evt) {
        evt = evt || window.event
        let isEscape = false
        const params = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop as string),
        })

        if ('key' in evt) {
          isEscape = evt.key === 'Escape' || evt.key === 'Esc'
        }
        if (isEscape) {
          // @ts-ignore
          if (params.image) {
            dispatch(setImagesList([], null))

            // @ts-ignore
            if (params.scene) {
              // @ts-ignore
              if (params.building && params.history) {
                navigate(
                  // @ts-ignore
                  `?scene=${params.scene}&building=${params.building}&history=${params.history}&uid=${params.uid}`
                )
              } else {
                // @ts-ignore
                navigate(`?scene=${params.scene}&uid=${params.uid}`)
              }
            } else {
              // @ts-ignore
              navigate(`?uid=${params.uid}`)
            }
          } else {
            // @ts-ignore
            if (params.scene) {
              // @ts-ignore
              navigate(`?scene=${params.scene}`)
            } else {
              navigate(``)
            }
          }
        }
      }
    }
  }, [loaded])

  /**
   * 데이터 불러오기
   */
  const fetchContentsData = async (modelDevKey, callback) => {
    const contentsReqs: any[] = []

    const getData = (page: number) => {
      const resAxios = getContentsDataList(
        {
          page: page,
          size: DATA_CHUNK_SIZE,
          direction: 'ASC',
        },
        modelDevKey
      )
      contentsReqs.push(resAxios)

      return resAxios.then((res) => {
        if (!res.data.pageInfo.isLast) {
          return getData(page + 1)
        }
      })
    }

    await getData(0)

    axios.all([...contentsReqs]).then(
      axios.spread(async (...responses) => {
        const dataArr: any[] = []
        responses.forEach((res) => {
          dataArr.push(...res.data.list)
        })

        callback(dataArr)
      })
    )
  }

  if (!loaded) return <div className="loading"></div>

  return (
    <div id={'wrap'}>
      <main id="main">{children}</main>
      {modalContents &&
      modalContents.data.contentsType !== 'resources' &&
      modalContents.data.contentsType !== 'histories' &&
      modalContents.data.contentsType !== 'episodes' &&
      vArchiveVideos.indexOf(modalContents.uid) === -1 ? (
        <ModalContents
          item={
            modalContents as
              | HistoriesItem
              | EpisodesItem
              | ProductsItem
              | vArchiveItem
              | RndMediaItem
          }
        />
      ) : (
        <></>
      )}
      {modalContents &&
      modalContents.data.contentsType !== 'resources' &&
      modalContents.data.contentsType !== 'histories' &&
      modalContents.data.contentsType === 'episodes' &&
      vArchiveVideos.indexOf(modalContents.uid) === -1 ? (
        <ModalContents
          className="modal-episodes"
          item={
            modalContents as
              | HistoriesItem
              | EpisodesItem
              | ProductsItem
              | vArchiveItem
              | RndMediaItem
          }
        />
      ) : (
        <></>
      )}
      {modalContents &&
      (modalContents.data.contentsType === 'resources' ||
        vArchiveVideos.indexOf(modalContents.uid) !== -1) ? (
        <ModalResource item={modalContents as ResourcesItem} />
      ) : (
        <></>
      )}
      <ModalImagesZoom />
    </div>
  )
}

export default Layout
