import React from 'react'

export default function SearchEra() {
  /**
   * 시대로 이동
   * @param era
   */
  const moveToEra = (era: number) => {
    const main = document.getElementById('search-modal')

    if (!main) return

    const searchItems = document.querySelectorAll(`[data-era='${era}']`)
    const currentTop = main.scrollTop

    let targetTop = 0

    searchItems.forEach((item, iIdx) => {
      const itemTop = currentTop + item.getBoundingClientRect().top

      if (!iIdx || (iIdx && itemTop <= targetTop)) {
        targetTop = itemTop
      }
    })

    main?.scrollTo({
      top: targetTop + 2,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div
      className="search-era-container w-max flex md:justify-center"
      style={{
        zIndex: 2000,
      }}>
      <ul
        id="search-era"
        className="search-era w-max flex items-center md:justify-center space-x-5 text-sm leading-4 font-ginto-nord px-6 py-4">
        {Array(9)
          .fill(1940)
          .map((m, idx) => (
            <li
              key={idx}
              id={`search-era-${m + idx * 10}`}
              data-era-nav={m + idx * 10}
              className={`search-era-item border-b cursor-pointer ${
                idx
                  ? 'color-gray border-transparent'
                  : 'color-black  border-black'
              }`}
              onClick={() => moveToEra(m + idx * 10)}>
              {m + idx * 10}
            </li>
          ))}
      </ul>
    </div>
  )
}
