import React from 'react'
import moment from 'moment'

function DateFormat({ str }) {
  return (
    <>
      {str && str.length === 8 && moment(str, 'YYYYMMDD').isValid()
        ? moment(str, 'YYYYMMDD').format('YYYY년 M월 D일')
        : str && str.length === 6 && moment(str, 'YYYYMM').isValid()
        ? moment(str, 'YYYYMM').format('YYYY년 M월')
        : str && str.length === 4 && moment(str, 'YYYY').isValid()
        ? moment(str, 'YYYY').format('YYYY년')
        : str &&
          str.length === 8 &&
          moment(str.substring(0, 4), 'YYYY').isValid()
        ? moment(str.substring(0, 4), 'YYYY').format('YYYY년')
        : str}
    </>
  )
}

export default DateFormat
