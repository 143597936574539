import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import { RootState } from '@/states/reducers'
import moment from 'moment'
import { useAppDispatch } from '@/states/store'
import { setImagesList } from '@/states/actions/contents.actions'

const mapVideos = {
  1: require('../../assets/images/01_schweiz.json'),
  2: require('../../assets/images/02_italia.json'),
  3: require('../../assets/images/03_france.json'),
  4: require('../../assets/images/04_nederland.json'),
  5: require('../../assets/images/05_usa.json'),
  6: require('../../assets/images/06_japan.json'),
}

const mapKey = {
  c7f479094712493cb7c7ce8d4386bbe9: 5,
  b5e1fe5ace974cdc95fe9d189a9986bd: 6,
  '3f0b3d10037c423cb2a26bca568fb9d9': 4,
  a60d858ac6b44ae6af4ffd23518f439d: 1,
  a4c92d43c4e44253a9bcd5bf111487b5: 5,
  '1ef59798a03b42599ec8d795d83af024': 4,
  '3dcd3c87a8e74d468baa9d114fb42fea': 5,
  b98f8169710c4daabd6d9332dbdec414: 5,
  d0dc7ebd35c747949b684b08c19a3330: 6,
  '683057159cd24378a47fa45cda638f8d': 3,
  '50b5b352d3e44c6e9681fc666cc97427': 6,
  '6f8c2b70bbe14d10a7aae6e4464f67dd': 6,
  '8e0a29c3c1d44c59ac775dd254bda9ea': 6,
  b8781af53e7e47d7bac642ed261a336d: 2,
}

function DateFormatCustom({ str }) {
  return (
    <>
      {str && str.length === 8 && moment(str, 'YYYYMMDD').isValid()
        ? moment(str, 'YYYYMMDD').format('YYYY.M.D')
        : str && str.length === 6 && moment(str, 'YYYYMM').isValid()
        ? moment(str, 'YYYYMM').format('YYYY.M')
        : str && str.length === 4 && moment(str, 'YYYY').isValid()
        ? moment(str, 'YYYY').format('YYYY')
        : str &&
          str.length === 8 &&
          moment(str.substring(0, 4), 'YYYY').isValid()
        ? moment(str.substring(0, 4), 'YYYY').format('YYYY')
        : str}
    </>
  )
}

const CustomContents2 = () => {
  const dispatch = useAppDispatch()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { histories } = contentsState

  // State
  const [current, setCurrent] = useState<number>(0)
  const [video, setVideo] = useState<number>(0)

  // Memo
  const customContents = useMemo(() => {
    return histories.filter(
      (h) =>
        h.data.companyBuilding[0].languageMap.KO === '하월곡' &&
        h.data.category[0].languageMap.KO === '제휴'
    )
  }, [histories])

  // Effect
  useEffect(() => {
    const scrollContainer = document.getElementById('modal-custom-2-list')

    scrollContainer?.addEventListener('wheel', (e) => {
      const _w = window.innerWidth

      if (_w >= 776) {
        e.preventDefault()

        // @ts-ignore
        scrollContainer.scrollLeft += e.deltaY
      }
    })

    setTimeout(() => {
      setVideo(mapKey['c7f479094712493cb7c7ce8d4386bbe9'])
    })
  }, [])

  /**
   * 콘텐츠 이동
   * @param idx
   * @returns
   */
  const moveToContents = (idx, uid) => {
    const container = document.getElementById('modal-custom-2-list')
    const target = document.getElementById(`modal-custom-2-item-${idx}`)
    const offset = 48

    if (!container || !target) return

    container.scrollTo({
      top: 0,
      left: target.offsetLeft - offset,
      behavior: 'smooth',
    })

    setCurrent(idx)

    // 비디오 재생
    if (mapKey[uid]) {
      setVideo(mapKey[uid])
    }
  }

  /**
   * 이미지 확대 모달 열기
   * @param images
   * @param image
   */
  const openImageZoom = (images, image) => {
    dispatch(setImagesList(images, image))
  }

  return (
    <div className="modal-custom-2 modal-custom-2-cursor-move w-full h-full space-y-0 md:space-y-60 overflow-hidden border-t border-black md:border-none relative">
      <div className="relative h-full">
        <div className="btn btn-mouse-wheel absolute top-4 left-6 hidden lg:block"></div>
        <div className="absolute top-4 right-6 space-x-7 z-50 hidden md:flex">
          <button
            type="button"
            className="btn btn-line btn-line-sm arrow-left w-10 bg-contain"
            onClick={() =>
              moveToContents(
                current > 0 ? current - 1 : 0,
                customContents[current > 0 ? current - 1 : 0].uid
              )
            }></button>
          <button
            type="button"
            className="btn btn-line btn-line-sm arrow-right w-10 bg-contain"
            onClick={() =>
              moveToContents(
                current < customContents.length - 1
                  ? current + 1
                  : customContents.length - 1,
                customContents[
                  current < customContents.length - 1
                    ? current + 1
                    : customContents.length - 1
                ].uid
              )
            }></button>
        </div>
        <div
          id="modal-custom-2-list"
          className="modal-custom-list modal-custom-2-list py-12 px-12 w-full h-full overflow-x-auto overflow-y-visible">
          <ul className="flex space-x-20 max-w">
            {customContents.map((item, idx) => (
              <li key={item.uid} id={`modal-custom-2-item-${idx}`}>
                <div className="mb-3">
                  <button
                    type="button"
                    className={`btn btn-modal-custom bg-white py-1.5 px-5 rounded-3xl font-ginto-nord ${
                      current === idx ? 'current' : ''
                    }`}
                    onClick={() => moveToContents(idx, item.uid)}>
                    <DateFormatCustom str={item.data.startDate.KO} />
                  </button>
                </div>
                <div>
                  <div className="para text-base">{item.data.title.KO}</div>
                  {item.data.relationImages &&
                  item.data.relationImages.length ? (
                    <div className="images mt-5 pb-10 md:pb-0">
                      <ul className="flex space-x-4">
                        {item.data.relationImages.map((image) => (
                          <li
                            key={image._id}
                            className="relative cursor-pointer"
                            onClick={() =>
                              openImageZoom(item.data.relationImages, image)
                            }>
                            <img
                              src={image.file.path}
                              alt={image.languageMap.KO?.name}
                            />
                            {image.languageMap.KO?.name ? (
                              <p className="absolute w-full mt-2 !text-sm font-medium leading-base text-clip  overflow-visible">
                                {image.languageMap.KO?.name}
                              </p>
                            ) : (
                              <></>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="modal-custom-2-map">
        {video ? (
          <Lottie
            isClickToPauseDisabled
            options={{
              loop: true,
              autoplay: true,
              animationData: mapVideos[video],
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width="100%"
            height="100%"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default CustomContents2
