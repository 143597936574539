import React from 'react'

const MiniContents2_1 = () => {
  return (
    <div className="space-y-10">
      <div>
        <img
          src={'/assets/img-mini-2-1.png'}
          alt="두번째 사옥인 하월곡동 사옥의 전경들"
          className="w-full block"
        />
      </div>
      <div className="space-y-3">
        <img
          src={'/assets/img-mini-2-2.png'}
          alt="두번째 사옥인 하월곡동 사옥의 전경들"
          className="w-full block"
        />
        <p className="text-xs font-medium">
          두번째 사옥인 하월곡동 사옥의 전경들
        </p>
      </div>
    </div>
  )
}

export default MiniContents2_1
