import React from 'react'
import { vArchiveItem } from '@/types'
import Images from '../Images'
import { customContentsList } from '@/configs'

export const ModalContentsvArchive = ({ item }: { item: vArchiveItem }) => {
  return item ? (
    <div className="modal-body-contents-inner">
      <div className="text-4xl leading-base">
        {item.data.title && item.data.title.KO ? (
          <div
            dangerouslySetInnerHTML={{
              __html: item.data.title.KO.replace(/\n/g, '<br />'),
            }}></div>
        ) : (
          <></>
        )}
      </div>
      {item.data.content && item.data.content.KO ? (
        <div className="mt-10">
          <div
            className="text-lg leading-lg font-eulyoo1945"
            dangerouslySetInnerHTML={{
              __html: item.data.content.KO.replace(/\n/g, '<br />'),
            }}></div>
        </div>
      ) : (
        <></>
      )}
      {/* {item.data.relationImages &&
      item.data.relationImages.length &&
      item.uid !== '768d0f58a1ec4b4c933d3fac90dc3f5a' ? (
        <div className="mt-32">
          <ul className="space-y-20">
            <Images images={item.data.relationImages} noGutter />
          </ul>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  ) : (
    <></>
  )
}
