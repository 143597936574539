import React, { useMemo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  EpisodesItem,
  HistoriesGroup,
  HistoriesItem,
  ProductsItem,
  ResourcesItem,
} from '@/types'
import DateFormat from '../DateFormat'
import Tags from '../Tags'
import SearchDonateItem from './SearchDonateItem'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import { krPanoScenes } from '@/configs'

const bgColor = {
  histories: 'white',
  episodes: 'green',
  products: 'blue',
  resources: 'white',
}

/**
 * 썸네일
 * @param param0
 * @returns
 */
function ThumbnailItem({
  item,
}: {
  item: HistoriesItem | EpisodesItem | ProductsItem | ResourcesItem
}) {
  return (
    <div
      className="search-item-thumbnail"
      /* style={{
        aspectRatio: `${item.data.mainImage[0].file.meta.width} / ${item.data.mainImage[0].file.meta.height}`,
      }} */
    >
      <LazyLoadImage
        src={
          item.data.mainImage[0].file.thumbnailList &&
          item.data.mainImage[0].file.thumbnailList.length &&
          item.data.mainImage[0].file.thumbnailList.find(
            (t) => t.type === 'LARGE'
          )
            ? item.data.mainImage[0].file.thumbnailList.find(
                (t) => t.type === 'LARGE'
              )?.path
            : item.data.mainImage[0].file.path
        }
        alt={item.data.sortName}
        className="w-full block"
      />
    </div>
  )
}

/**
 * 연혁
 * @param param0
 * @returns
 */
function HistoryItem({ item }: { item: HistoriesItem }) {
  return (
    <div className="search-item-container">
      <div className="search-item-date">
        {item.data.startDate.KO?.substring(0, 4)}
      </div>
      {item.data.mainImage && item.data.mainImage[0] ? (
        <ThumbnailItem item={item} />
      ) : (
        <></>
      )}
      <div className="search-item-info p-3 pb-2">
        <div className="font-medium leading-md mb-1">
          {item.data.title ? item.data.title.KO : ''}
        </div>
        <div className="text-sm font-medium leading-md">
          <DateFormat str={item.data.sortDate} />
        </div>
        <div className="search-item-tags mt-6 flex">
          <Tags
            tags={[
              {
                _id: 0,
                order: 0,
                languageMap: {
                  KO: '연혁',
                },
              },
            ]}
            color="light-yellow"
          />
          {item.data.innovationTags && item.data.innovationTags.length ? (
            <Tags tags={item.data.innovationTags} color="extra-light-gray" />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * 에피소드
 * @param param0
 * @returns
 */
function EpisodeItem({ item }: { item: EpisodesItem }) {
  return (
    <div className="search-item-container">
      <div className="search-item-date">
        {item.data.startDate.KO?.substring(0, 4)}
      </div>
      <p className="line-clamp-3 text-sm leading-lg px-3">
        {item.data.content.KO}
      </p>
      {item.data.mainImage && item.data.mainImage[0] ? (
        <ThumbnailItem item={item} />
      ) : (
        <></>
      )}
      <div className="search-item-info p-3 pb-2">
        <div className="font-medium leading-md mb-1">
          {item.data.title ? item.data.title.KO : ''}
        </div>
        <div className="text-sm font-medium leading-md">
          <DateFormat str={item.data.sortDate} />
        </div>
        <div className="search-item-tags mt-5 flex">
          <Tags
            tags={[
              {
                _id: 0,
                order: 0,
                languageMap: {
                  KO: '에피소드',
                },
              },
            ]}
            color="light-green"
          />
          {item.data.innovationTags && item.data.innovationTags.length ? (
            <Tags tags={item.data.innovationTags} color="extra-light-gray" />
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* 
      <div className="text-center text-sm font-bold first:py-px leading-md mb-4">
        {item.data.title ? item.data.title.KO : ''}
      </div>
      <p className="line-clamp-3 text-sm leading-lg">{item.data.content.KO}</p> */}
    </div>
  )
}

/**
 * 에피소드
 * @param param0
 * @returns
 */
function ProductItem({ item }: { item: ProductsItem }) {
  return (
    <div className="search-item-container">
      {item.data.mainImage && item.data.mainImage[0] ? (
        <ThumbnailItem item={item} />
      ) : (
        <></>
      )}
      <div className="search-item-info p-3 pb-2">
        <div className="font-medium leading-md mb-1">
          {item.data.nameKor ? item.data.nameKor.KO : ''}
        </div>
        <div className="text-sm font-medium leading-md">
          <DateFormat str={item.data.sortDate} />
        </div>
        <div className="search-item-tags mt-6 flex">
          <Tags
            tags={[
              {
                _id: 0,
                order: 0,
                languageMap: {
                  KO: '제품',
                },
              },
            ]}
            color="light-blue"
          />
          {item.data.innovationTags && item.data.innovationTags.length ? (
            <Tags tags={item.data.innovationTags} color="extra-light-gray" />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

/**
 * 미디어
 * @param param0
 * @returns
 */
function ResourceItem({ item }: { item: ResourcesItem }) {
  return (
    <div className="search-item-container">
      <div className="search-item-date">
        {item.data.startDate.KO?.substring(0, 4)}
      </div>
      <p className="line-clamp-3 text-sm leading-lg px-3">
        {item.data.content.KO}
      </p>
      {item.data.mainImage && item.data.mainImage[0] ? (
        <ThumbnailItem item={item} />
      ) : (
        <div className="search-item-fake-image rounded-2xl" />
      )}
    </div>
  )
}

export default function SearchItem({
  item,
  prevItem,
  nextItem,
  idx,
}: {
  item: HistoriesItem | EpisodesItem | ProductsItem | ResourcesItem
  prevItem:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | ResourcesItem
    | null
    | undefined
  nextItem:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | ResourcesItem
    | null
    | undefined
  idx: number
}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { histories } = contentsState

  /**
   * 모달 열기
   */
  const openModal = () => {
    if (item.data.contentsType === 'histories') {
      let historyGroupIdx = 0
      const currentSceneBuilding = item.data.companyBuilding[0].languageMap.KO
      const currentSceneName = krPanoScenes.find(
        (k) => k.building === currentSceneBuilding
      )
      const historiesByBuilding = histories.filter(
        (h) => h.data.companyBuilding[0].languageMap.KO === currentSceneBuilding
      )
      const historiesGroup: HistoriesGroup[] = []
      historiesByBuilding.forEach((h) => {
        const year = h.data.startDate.KO?.substring(0, 4) as string
        if (historiesGroup.find((hg) => hg.year === year)) {
          historiesGroup.find((hg) => hg.year === year)?.items.push(h)
        } else {
          historiesGroup.push({
            year,
            items: [h],
          })
        }
      })

      // 정렬
      historiesGroup.sort((a, b) =>
        a.year > b.year ? 1 : b.year > a.year ? -1 : 0
      )

      historiesGroup.forEach((hg) => {
        hg.items.sort((a, b) =>
          // @ts-ignore
          a.data.startDate.KO > b.data.startDate.KO
            ? 1
            : // @ts-ignore
            b.data.startDate.KO > a.data.startDate.KO
            ? -1
            : 0
        )
      })

      historiesGroup.forEach((hg, hgIdx) => {
        hg.items.forEach((hgi, hgiIdx) => {
          if (hgi.uid === item.uid) {
            historyGroupIdx = hgIdx
          }
        })
      })

      navigate(
        `?scene=${currentSceneName?.scene}&building=${currentSceneBuilding}&history=${historyGroupIdx}&uid=${item.uid}`
      )
    } else {
      navigate(`?scene=${searchParams.get('scene')}&uid=${item.uid}`)
    }
  }

  return (
    <>
      <div
        className={`search-item search-item-${item.data.contentsType} ${
          item.data.mainImage && item.data.mainImage[0]
            ? 'has-thumbnail'
            : 'not-has-thumbnail'
        } cursor-pointer`}
        id={`search-item-${item.uid}`}
        data-era={item.data.eraDate ? item.data.eraDate.substring(0, 4) : ''}
        onClick={() => openModal()}>
        {item.data.contentsType === 'histories' ? (
          <HistoryItem item={item as HistoriesItem} />
        ) : (
          <></>
        )}
        {item.data.contentsType === 'episodes' ? (
          <EpisodeItem item={item as EpisodesItem} />
        ) : (
          <></>
        )}
        {item.data.contentsType === 'products' ? (
          <ProductItem item={item as ProductsItem} />
        ) : (
          <></>
        )}
        {item.data.contentsType === 'resources' ? (
          <ResourceItem item={item as ResourcesItem} />
        ) : (
          <></>
        )}
      </div>
      {/* {idx === 3 ? <SearchDonateItem /> : <></>} */}
    </>
  )
}
