import React from 'react'
import { useAppDispatch } from '@/states/store'
import { toggleBg, toggleGuide } from '@/states/actions/layout.actions'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'

const Guide = () => {
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { guide } = layoutState

  return (
    <div id="guide" className={`guide ${guide ? '' : 'hidden'}`}>
      <div className="guide-contents">
        <div className="grid grid-cols-2 md:grid-cols-5 md:gap-4">
          <div className="border-b md:border-b-0 border-r border-black pb-4 md:pb-0 pr-3 md:pr-4">
            <div className="guide-icon move"></div>
            <p className="text-sm leading-md">
              바닥의 이동 버튼을 클릭하면 해당 공간 앞으로 이동할 수 있습니다.
            </p>
          </div>
          <div className="border-b md:border-b-0 md:border-r border-black pb-4 md:pb-0 pr-3 md:pr-4 pl-3 md:pl-0">
            <div className="guide-icon point"></div>
            <p className="text-sm leading-md">
              공간 곳곳에 위치한 콘텐츠 버튼을 클릭하면 자세한 내용을 확인할 수
              있습니다.
            </p>
          </div>
          <div className="border-b md:border-b-0 md:border-r border-black pb-4 md:pb-0 pr-0 md:pr-4 hidden md:block">
            <div className="guide-icon mouse"></div>
            <p className="text-sm leading-md">
              마우스 휠을 사용해 확대/축소가 가능합니다.
            </p>
          </div>
          <div className="border-b md:border-b-0 border-r border-black pb-4 md:pb-0 pr-3 md:pr-4 md:hidden pt-3 md:pt-0">
            <div className="guide-icon pinch"></div>
            <p className="text-sm leading-md">
              두 손가락으로 확대/축소할 수 있습니다.
            </p>
          </div>
          <div className="border-b md:border-b-0 md:border-r border-black pb-4 md:pb-0 pr-0 md:pr-4 hidden md:block">
            <div className="guide-icon keyboard"></div>
            <p className="text-sm leading-md">
              키보드의 방향키 또는 마우스 드래그를 이용하여 시점을 변경할 수
              있습니다.
            </p>
          </div>
          <div className="pr-4 border-b border-black md:border-b-0 pl-3 md:pl-0 pb-8 md:pb-0 pt-3 md:pt-0">
            <div className="guide-icon menu"></div>
            <p className="text-sm leading-md">
              우측 상단의 메뉴를 통해 사옥별 전시 공간으로 바로 이동할 수
              있습니다
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <button
            type="button"
            className="text-lg py-4 px-5 leading-4 bg-white rounded-3xl font-medium"
            onClick={() => {
              dispatch(toggleGuide(false))
              dispatch(toggleBg(true))
            }}>
            전시 관람하기
          </button>
        </div>
      </div>
      <div
        className="guide-bg"
        onClick={() => {
          dispatch(toggleGuide(false))
          dispatch(toggleBg(true))
        }}
      />
    </div>
  )
}

export default Guide
