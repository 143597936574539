import React, { useEffect, useMemo, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import Slider from 'react-slick'
import { useAppDispatch } from '@/states/store'

const contentsList = [
  {
    title: '조제',
    contents:
      '커다란 조제 탱크에 증류수와 포도당 등 원료를 넣고 가열한다. 긴 막대 모양에 모터가 달린 교반기로 섞어 약액을 만든다.',
    image: `/assets/img-custom-3-1.png`,
  },
  {
    title: '여과',
    contents:
      '조제한 약액을 여과하는 여과기 설비.<br/>자이츠(Seitz) 여과기와 더불어 좀 더 효율성이 높은 밀리포아(Millipore) 여과기를 추가 도입했다.',
    image: `/assets/img-custom-3-2.png`,
  },
  {
    title: '충전',
    contents:
      '페달처럼 생긴 발판을 밝으면 충전 노즐을 통해 약액이 충전되는 구조다.',
    image: `/assets/img-custom-3-3.png`,
  },
  {
    title: '진공',
    contents:
      '진공타전기 입구에 유리병을 넣고 상단의 눌림대를 힘주어 누르면 진공호스를 통해 공기가 빠져나가는 구조이다. 가운데 있는 진공계를 통해 진공 여부를 확인했다.',
    image: `/assets/img-custom-3-4.png`,
  },
  {
    title: '멸균',
    contents:
      '한 단에 24개의 수액이 여섯 박스씩 들어갈 수 있는 멸균기. 1975년 자동온도기록계로 교체하면서 보다 멸균의 정확도를 높일 수 있었다.',
    image: `/assets/img-custom-3-5.png`,
  },
  {
    title: '이물검사',
    contents:
      '상단의 반사경에 불투명 백열전구를 달아 빛을 비추고 흑판과 백판을 나란히 두어 이물을 검사했다.',
    image: `/assets/img-custom-3-6.png`,
  },
  {
    title: '포장',
    contents:
      '15개들이 나무상자에 넣고 새끼줄로 묶던 방식에서 박스밴딩 포장기를 토입해 보다 깔끔하고 말쑥해진 모습을 갖출 수 있었다.',
    image: `/assets/img-custom-3-7.png`,
  },
]

const CustomContents3 = () => {
  const dispatch = useAppDispatch()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { histories } = contentsState

  // State
  const [busy, setBusy] = useState<boolean>(false)
  const [current, setCurrent] = useState<number>(0)

  // Memo
  const customContents = useMemo(() => {
    return histories.filter(
      (h) =>
        h.data.companyBuilding[0].languageMap.KO === '하월곡' &&
        h.data.category[0].languageMap.KO === '제휴'
    )
  }, [histories])

  // Effect
  useEffect(() => {
    const scrollContainer = document.getElementById('modal-custom-list-inner')

    scrollContainer?.addEventListener('wheel', (e) => {
      const _w = window.innerWidth

      if (_w >= 776) {
        e.preventDefault()

        // @ts-ignore
        scrollContainer.scrollLeft += e.deltaY
      }
    })
  }, [])

  // Slick
  const sliderRef = useRef()
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (e) => {
      onChangeSlide(e)
    },
  }

  const onChangeSlide = (idx) => {
    moveToContents(idx)
  }

  /**
   * 콘텐츠 이동
   * @param idx
   * @returns
   */
  const moveToContents = (idx) => {
    if (busy) return
    setBusy(true)

    const container = document.getElementById('modal-custom-2-list')
    const target = document.getElementById(`modal-custom-2-item-${idx}`)
    const offset = 48
    const scrollContainer = document.getElementById('modal-custom-list-inner')

    if (!container || !target) return

    container.scrollTo({
      top: 0,
      left: target.offsetLeft - offset,
      behavior: 'smooth',
    })

    setCurrent(idx)

    // @ts-ignore
    sliderRef?.current?.slickGoTo(idx)

    setTimeout(() => {
      setBusy(false)
    }, 500)

    if (scrollContainer && window.innerWidth < 776) {
      scrollContainer.scrollTo({
        top: 0,
        left:
          target.offsetLeft -
          window.innerWidth * 0.5 +
          target.clientWidth * 0.5,
        behavior: 'smooth',
      })
    } else if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        left: target.offsetLeft - 32,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className="modal-custom-2 w-full md:h-full space-y-60 overflow-hidden pb-40 md:pb-0">
      <div
        id="modal-custom-2-list"
        className="modal-custom-2-list py-12 w-full h-[520px] md:h-full flex flex-col justify-between ">
        <div className="absolute top-4 right-6 space-x-7 z-50 hidden md:flex">
          <button
            type="button"
            className="btn btn-line btn-line-sm arrow-left w-10 bg-contain"
            onClick={() =>
              moveToContents(current > 0 ? current - 1 : 0)
            }></button>
          <button
            type="button"
            className="btn btn-line btn-line-sm arrow-right w-10 bg-contain"
            onClick={() =>
              moveToContents(
                current < contentsList.length - 1
                  ? current + 1
                  : contentsList.length - 1
              )
            }></button>
        </div>
        <div
          id="modal-custom-list-inner"
          className="modal-custom-list w-full overflow-x-auto md:px-8">
          <ul className="flex w-max  px-52 md:px-0">
            {contentsList.map((c, cIdx) => (
              <li key={cIdx} id={`modal-custom-2-item-${cIdx}`}>
                <div className="space-y-6">
                  <button
                    type="button"
                    className={`bg-white py-2 px-5 text-lg rounded-3xl leading-5 font-medium border border-transparent btn-modal-custom ${
                      current === cIdx ? 'current' : ''
                    }`}
                    onClick={() => moveToContents(cIdx)}>
                    {c.title}
                  </button>
                  <p
                    className={`hidden md:block absolute left-8 w-[320px] leading-lg transition-all ${
                      current !== cIdx
                        ? 'opacity-0 invisible'
                        : 'opacity-1 visible'
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: contentsList[cIdx].contents.replace(
                        /\n/g,
                        '<br />'
                      ),
                    }}></p>
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="absolute bottom-0 left-0 w-full h-full slider-custom">
          <div className="btn btn-mouse-wheel absolute top-4 left-6 hidden lg:block"></div>
          <Slider {...settings} ref={sliderRef}>
            {contentsList.map((c, cIdx) => (
              <div key={cIdx} className="w-full h-full flex items-end">
                <p
                  className={`md:hidden pl-5 pr-12 leading-lg transition-all ${
                    current !== cIdx
                      ? 'opacity-0 invisible'
                      : 'opacity-1 visible'
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: contentsList[cIdx].contents.replace(
                      /\n/g,
                      '<br />'
                    ),
                  }}></p>
                <img src={c.image} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default CustomContents3
