import React from 'react'

const MiniContents2_2 = () => {
  return (
    <div className="space-y-10">
      <div className="space-y-3">
        <img
          src={'/assets/img-mini-3-1.png'}
          alt="하월곡동 사옥의 수액 모양의 분수"
          className="w-full block"
        />
        <p className="text-xs font-medium">하월곡동 사옥의 수액 모양의 분수</p>
      </div>
    </div>
  )
}

export default MiniContents2_2
