import { JwBuildingsType, MiniContentsInterface, SceneInterface } from '@/types'

/**
 * 프로젝트 UID
 */
export const projectUid = '9796c4d4'

/**
 * 데이터 모델키
 */
export const modelKey = {
  histories: 'histories',
  episodes: 'episodes',
  products: 'products',
  resources: 'resources',
  vArchive: 'vArchive',
  rndMedia: 'rndMedia',
}
export const DEFAULT_PAGE_SIZE = 15
export const DATA_CHUNK_SIZE = 100

/**
 * JW 이노베이션태그
 */
export const jwTags = ['혁신', '도전', '동행', '신뢰', '확장', '협력', '문화']

/**
 * JW 검색 모델 종류
 */
export const jwModels = [
  {
    modelKey: 'histories',
    label: '연혁',
  },
  {
    modelKey: 'episodes',
    label: '에피소드',
  },
  {
    modelKey: 'products',
    label: '제품',
  },
  {
    modelKey: 'resources',
    label: '미디어',
  },
]

/**
 * JW 빌딩 모음
 */
export const jwBuildings: JwBuildingsType[] = [
  '충무로',
  '하월곡',
  '신대방',
  '서초',
  '과천',
]

/**
 * KR pano Scene
 */
export const krPanoScenes: SceneInterface[] = [
  {
    label:
      '<span class="name">충무로시대</span><span class="year">1945</span><span class="arrow"></span><span class="year">1966</span>',
    scene: 'scene__ecpdsadhc_0217-1',
    building: '충무로',
    text: '희망을 담은 창조의 연못',
  },
  {
    label:
      '<span class="name">하월곡시대</span><span class="year">1966</span><span class="arrow"></span><span class="year">1986</span>',
    scene: 'scene__fvyebuczh_0208-1',
    building: '하월곡',
    text: '정공법으로 타개한 위기, 정상궤도를 향해 나아가다',
  },
  {
    label:
      '<span class="name">신대방시대</span><span class="year">1986</span><span class="arrow"></span><span class="year">2012</span>',
    scene: 'scene__elgdmadwp_0208-1',
    building: '신대방',
    text: '글로벌 헬스케어 기업을 향한 인프라를 구축하다',
  },
  {
    label:
      '<span class="name">서초시대</span><span class="year">2012</span><span class="arrow"></span><span class="year">2023</span>',
    scene: 'scene__eeceai_0220-1',
    building: '서초',
    text: 'R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다',
  },
  {
    label:
      '<span class="name">과천시대</span><span class="year">2023</span><span class="arrow arrow-long"></span>',
    scene: 'scene__czieyc_0220-1',
    building: '과천',
    text: '새로운 역사의 시작',
  },
]

/**
 * 미니 콘텐츠 내용
 */
export const miniContentsList: MiniContentsInterface[] = [
  {
    id: '충무로-1',
    building: '충무로',
    dir: 'left',
  },
  {
    id: '하월곡-1',
    building: '하월곡',
    dir: 'right',
  },
  {
    id: '하월곡-2',
    building: '하월곡',
    dir: 'right',
  },
  {
    id: '하월곡-3',
    building: '하월곡',
    dir: 'right',
  },
  {
    id: '하월곡-4',
    building: '하월곡',
    dir: 'right',
  },
  {
    id: '서초-1',
    building: '서초',
    dir: 'left',
  },
]

/**
 * 커스텀 콘텐츠 내용
 */
export const customContentsList: string[] = [
  '19bcefc507ec413ba10bc265e548934f',
  'a78bedda27b84053a2a86deb15252507',
  'feb6acb0d26e47418a4e7894a6a24c83',
  // '768d0f58a1ec4b4c933d3fac90dc3f5a',
]

/**
 * 버추얼 아카이브 광고영상
 */
export const vArchiveVideos: string[] = [
  'ad11406c05d84beba07857efb0743053',
  '010d20610b53424188026ea22d54f53d',
  '1a9fc0eb8d7a479b9863153f5544d3e8',
  '0a390f57a4764007ad6cfc65fb90952a',
]

/**
 * 이전,이후 콘텐츠 지정
 */
export const neighborContents = {
  '0e545987a8fa434b90c6b96a4db4b9d9': {
    next: null,
    prev: '57479b7769c94752b764592842810793',
  },
  '57479b7769c94752b764592842810793': {
    next: '0e545987a8fa434b90c6b96a4db4b9d9',
    prev: 'c02c6c92348842a88dfebbb4a0ec77dc',
  },
  c02c6c92348842a88dfebbb4a0ec77dc: {
    next: '57479b7769c94752b764592842810793',
    prev: 'ec1d549ab2b748e684bead30333e9274',
  },
  ec1d549ab2b748e684bead30333e9274: {
    next: 'c02c6c92348842a88dfebbb4a0ec77dc',
    prev: 'a13bd46adf2549c8aa3c81dd820812de',
  },
  a13bd46adf2549c8aa3c81dd820812de: {
    next: 'ec1d549ab2b748e684bead30333e9274',
    prev: '614c616efe42481e995287661dbff40c',
  },
  '614c616efe42481e995287661dbff40c': {
    next: 'a13bd46adf2549c8aa3c81dd820812de',
    prev: 'a39d64adf277419399d93b452ac537e6',
  },
  a39d64adf277419399d93b452ac537e6: {
    next: '614c616efe42481e995287661dbff40c',
    prev: 'ca82175553d04d7ebf29f29da018997e',
  },
  ca82175553d04d7ebf29f29da018997e: {
    next: 'a39d64adf277419399d93b452ac537e6',
    prev: 'cb010ab122864cb88e779dbfe0e1fd32',
  },
  cb010ab122864cb88e779dbfe0e1fd32: {
    next: 'ca82175553d04d7ebf29f29da018997e',
    prev: '3caf0f55bbd847d39e99d9e9028d6fc6',
  },
  '3caf0f55bbd847d39e99d9e9028d6fc6': {
    next: 'cb010ab122864cb88e779dbfe0e1fd32',
    prev: null,
  },
  '188b44409cb04d4fb619c22233062e09': {
    next: null,
    prev: '5221b285c89b4549b4fa6ccb2eafa04e',
  },
  '5221b285c89b4549b4fa6ccb2eafa04e': {
    next: '188b44409cb04d4fb619c22233062e09',
    prev: '6103e018d01742f184923988984d9b58',
  },
  '6103e018d01742f184923988984d9b58': {
    next: '5221b285c89b4549b4fa6ccb2eafa04e',
    prev: '9be1fe6fd292485b87cddb8d1b7a50ab',
  },
  '9be1fe6fd292485b87cddb8d1b7a50ab': {
    next: '6103e018d01742f184923988984d9b58',
    prev: 'b5e1fe5ace974cdc95fe9d189a9986bd',
  },
  b5e1fe5ace974cdc95fe9d189a9986bd: {
    next: '9be1fe6fd292485b87cddb8d1b7a50ab',
    prev: '206ed38a4268411aa0b29c5479483b5b',
  },
  '206ed38a4268411aa0b29c5479483b5b': {
    next: 'b5e1fe5ace974cdc95fe9d189a9986bd',
    prev: 'f55051dac9dc407993b909d87be0263b',
  },
  f55051dac9dc407993b909d87be0263b: {
    next: '206ed38a4268411aa0b29c5479483b5b',
    prev: 'bf51691375424b2f8e1f6c9d5e4ab5c9',
  },
  bf51691375424b2f8e1f6c9d5e4ab5c9: {
    next: 'f55051dac9dc407993b909d87be0263b',
    prev: '818ae57488dd4c22a0739dd180463e63',
  },
  '818ae57488dd4c22a0739dd180463e63': {
    next: 'bf51691375424b2f8e1f6c9d5e4ab5c9',
    prev: 'f97ee4dfe8914e9d84e4c6a5cdfbe111',
  },
  f97ee4dfe8914e9d84e4c6a5cdfbe111: {
    next: '818ae57488dd4c22a0739dd180463e63',
    prev: null,
  },

  '560a92021a254540926a0236042996dd': {
    next: '228ac58f2cf74fab925f92b152f715f9',
    prev: null,
  },
  '228ac58f2cf74fab925f92b152f715f9': {
    next: '9bbaf158ff6b4fc0989824cd3a8ed573',
    prev: '560a92021a254540926a0236042996dd',
  },

  '9bbaf158ff6b4fc0989824cd3a8ed573': {
    next: '3398e49e460541a98edd127325254abc',
    prev: '228ac58f2cf74fab925f92b152f715f9',
  },
  '3398e49e460541a98edd127325254abc': {
    next: 'dd345615d6ef4a16837c81d728ac3e58',
    prev: '9bbaf158ff6b4fc0989824cd3a8ed573',
  },
  dd345615d6ef4a16837c81d728ac3e58: {
    next: '05087118b2564895af509bc5b0a5e1bd',
    prev: '3398e49e460541a98edd127325254abc',
  },
  '05087118b2564895af509bc5b0a5e1bd': {
    next: '7ffdfe6a59834186a37b6dcb7f36ea8d',
    prev: 'dd345615d6ef4a16837c81d728ac3e58',
  },
  '7ffdfe6a59834186a37b6dcb7f36ea8d': {
    next: '8bbf51f78d8b4ee98415beb8c977da3d',
    prev: '05087118b2564895af509bc5b0a5e1bd',
  },
  '8bbf51f78d8b4ee98415beb8c977da3d': {
    next: '91ae98c02ea64aa7bd2c36a2b6a394a8',
    prev: '7ffdfe6a59834186a37b6dcb7f36ea8d',
  },
  '91ae98c02ea64aa7bd2c36a2b6a394a8': {
    next: 'df7c20957b504995905aad232fa72c9a',
    prev: '8bbf51f78d8b4ee98415beb8c977da3d',
  },
  df7c20957b504995905aad232fa72c9a: {
    next: '90fe981e31f44e21a79c6f7b3a7bc10d',
    prev: '91ae98c02ea64aa7bd2c36a2b6a394a8',
  },
  '90fe981e31f44e21a79c6f7b3a7bc10d': {
    next: '898005c22c2b4241bfacd9d3c55a62f0',
    prev: 'df7c20957b504995905aad232fa72c9a',
  },
  '898005c22c2b4241bfacd9d3c55a62f0': {
    next: null,
    prev: '90fe981e31f44e21a79c6f7b3a7bc10d',
  },
  ba19e431cc6b45e5a01320527594c51d: {
    next: '4798bf2f14c14a7599fc74e1b67a6845',
    prev: null,
  },
  '4798bf2f14c14a7599fc74e1b67a6845': {
    next: 'bbfcb996a9c24acea7016e4901b7a212',
    prev: 'ba19e431cc6b45e5a01320527594c51d',
  },
  bbfcb996a9c24acea7016e4901b7a212: {
    next: '83ef9f4af1114143a9f4ce6382d8e4ff',
    prev: '4798bf2f14c14a7599fc74e1b67a6845',
  },

  '83ef9f4af1114143a9f4ce6382d8e4ff': {
    next: '5afce69025ba4c19abaaa00073756fd1',
    prev: 'bbfcb996a9c24acea7016e4901b7a212',
  },

  '5afce69025ba4c19abaaa00073756fd1': {
    next: '6a2fb3a85fc04d7eb2aaf31220f3f197',
    prev: '83ef9f4af1114143a9f4ce6382d8e4ff',
  },
  '6a2fb3a85fc04d7eb2aaf31220f3f197': {
    next: '7fc163c7f2144c5ba7d2bc946f734c95',
    prev: '5afce69025ba4c19abaaa00073756fd1',
  },

  '7fc163c7f2144c5ba7d2bc946f734c95': {
    next: '3b55b3f3517a49548574d552bd8b9ecb',
    prev: '6a2fb3a85fc04d7eb2aaf31220f3f197',
  },

  '3b55b3f3517a49548574d552bd8b9ecb': {
    next: '72e4f0dc431940f382239a3ac6cd1922',
    prev: '7fc163c7f2144c5ba7d2bc946f734c95',
  },
  '72e4f0dc431940f382239a3ac6cd1922': {
    next: null,
    prev: '3b55b3f3517a49548574d552bd8b9ecb',
  },
}

/**
 * 빌딩별 오디오 파일
 */
export const audioByBuilding = {
  충무로: process.env.REACT_APP_ASSETS + '/assets/audio-1.mp3',
  하월곡: process.env.REACT_APP_ASSETS + '/assets/audio-1.mp3',
  신대방: process.env.REACT_APP_ASSETS + '/assets/audio-2.mp3',
  서초: process.env.REACT_APP_ASSETS + '/assets/audio-2.mp3',
  과천: process.env.REACT_APP_ASSETS + '/assets/audio-3.mp3',
}

/**
 * 빌딩별 VR 파일
 */
export const vrByBuilding = {
  충무로: 'vr_1',
  하월곡: 'vr_2',
  신대방: 'vr_3',
  서초: 'vr_4',
  과천: 'vr_5',
}
