import { AppDispatch } from '@/states/store'
import { ActionTypesLayout } from '@/states/interfaces'

/**
 * GNB 설정
 */
export const toggleGnb = (nav: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.TOGGLE_NAV,
    payload: {
      nav,
    },
  })
}

/**
 * Archive search modal 설정
 */
export const toggleArchive = (archive: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.TOGGLE_ARCHIVE,
    payload: {
      archive,
    },
  })
}

/**
 * 가이드 여닫기
 */
export const toggleGuide = (guide: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.TOGGLE_GUIDE,
    payload: {
      guide,
    },
  })
}

/**
 * bg 켜기/끄기
 */
export const toggleBg = (bg: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.TOGGLE_BG,
    payload: {
      bg,
    },
  })
}

/**
 * 이전 URL 가져오기
 */
export const getPreviousUrl = (url: string) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.GET_PREVIOUS_URL,
    payload: {
      url,
    },
  })
}
