import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { MediaInterface, ResourcesItem } from '@/types'
import Tags from '../Tags'
import { youtubeParser } from '@/utils'
import { YoutubePlayer } from '../YoutubePlayer'

export default function ModalResource({ item }: { item: ResourcesItem }) {
  const navigate = useNavigate()

  // State
  const [open, setOpen] = useState<boolean>(false)
  const [idx, setIdx] = useState<number>(0)
  const [currentResource, setCurrentResource] = useState<MediaInterface | null>(
    null
  )
  const [playing, setPlaying] = useState<boolean>(false)

  // Memo
  const relatedMedias = useMemo(() => {
    const images = item.data.relationImages ? item.data.relationImages : []
    const videos = item.data.relationVideos ? item.data.relationVideos : []

    return [...images, ...videos]
  }, [item])

  // Effect
  useEffect(() => {
    setIdx(0)
    setCurrentResource(relatedMedias[0])

    const scrollContainer = document.getElementById(
      'modal-contents-footer-resource-nav'
    )

    scrollContainer?.addEventListener('wheel', (e) => {
      e.preventDefault()

      // @ts-ignore
      scrollContainer.scrollLeft += e.deltaY
    })

    setOpen(true)
  }, [item])

  /**
   * 미디어 리소스 변경
   * @param idx
   */
  const setResource = (idx) => {
    const targetIDx =
      idx > 0
        ? idx < relatedMedias.length
          ? idx
          : relatedMedias.length - 1
        : 0
    setIdx(targetIDx)
    setCurrentResource(null)

    setTimeout(() => {
      setCurrentResource(relatedMedias[targetIDx])

      const scrollContainer = document.getElementById(
        'modal-contents-footer-resource-nav'
      )
      const target = document.getElementById(
        `modal-contents-footer-resource-item-${idx}`
      )
      const offset = 12

      if (!scrollContainer || !target) return

      scrollContainer.scrollTo({
        top: 0,
        left: target.offsetLeft - 12,
        behavior: 'smooth',
      })
    })
  }

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = (e) => {
    if (e.target.className === 'modal-container') {
      navigate('')
    }
  }

  return (
    <div className={`modal modal-resource ${open ? 'open' : ''}`}>
      <div className="modal-bg" onClick={() => navigate('')}></div>
      <div className="modal-container" onClick={(e) => closeModal(e)}>
        <div
          className="modal-contents"
          style={{
            filter: `drop-shadow(8px 0px 12px rgba(0, 0, 0, 0.15))`,
            zIndex: 200,
          }}>
          <div id="modal-contents-header" className="modal-header truncate">
            {currentResource?.languageMap.KO?.name}
          </div>
          <div id="modal-contents-body" className="modal-body">
            {currentResource?.mediaType === 'FILE' ? (
              <div
                className="modal-body-media bg-no-repeat bg-cover bg-center"
                style={{
                  backgroundImage: `url(${currentResource.file.path})`,
                }}></div>
            ) : (
              <div className="modal-body-media">
                {currentResource && currentResource.value ? (
                  <YoutubePlayer item={currentResource} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <div
            id="modal-contents-footer"
            className="modal-footer md:px-3 pb-1 md:pb-2.5">
            <div className="md:flex justify-between">
              <div
                id="modal-contents-footer-resource-nav"
                className="modal-contents-footer-resource-nav w-full grow overflow-x-auto lg:h-20">
                <ul className="w-max flex lg:mt-8">
                  {relatedMedias.map((nav, nIdx) => (
                    <li
                      key={nav._id}
                      id={`modal-contents-footer-resource-item-${nIdx}`}
                      className={`btn btn-pill bg-white ${
                        nIdx === idx ? 'active' : ''
                      } cursor-pointer`}
                      onClick={() => setResource(nIdx)}>
                      {nav.languageMap.KO?.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-contents-nav-footer mt-2.5 lg:mt-8 flex-none flex justify-between md:justify-end space-x-10 px-3 md:px-0">
                <div className="flex space-x-10">
                  <button
                    type="button"
                    className="btn btn-line btn-line-inline-long arrow-left"
                    onClick={() => setResource(idx - 1)}></button>
                  <button
                    type="button"
                    className="btn btn-line btn-line-inline-long arrow-right"
                    onClick={() => setResource(idx + 1)}></button>
                </div>
                <button
                  type="button"
                  className="btn btn-line close w-[48px] h-[48px]"
                  onClick={() => navigate('')}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
