import React, { useMemo, useState } from 'react'
import {
  EpisodesItem,
  HistoriesGroup,
  HistoriesItem,
  ProductsItem,
} from '@/types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { krPanoScenes } from '@/configs'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

const ModalRelatedContents = ({
  item,
}: {
  item: HistoriesItem | EpisodesItem | ProductsItem
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { contentsState, layouteState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
      layouteState: state.layout,
    }),
    shallowEqual
  )
  const { histories, importantHistories, episodes, products } = contentsState
  const { archive } = layouteState

  // State
  const [open, setOpen] = useState<boolean>(false)

  // Memo
  const allContents = useMemo(() => {
    return [...histories, ...episodes, ...products]
  }, [histories, episodes, products])

  /**
   * 모달 열기
   */
  const openModal = (targetItem) => {
    if (targetItem.data.contentsType === 'histories') {
      let historyGroupIdx = 0
      const targetHistories = archive ? histories : importantHistories
      const currentSceneBuilding =
        targetItem.data.companyBuilding[0].languageMap.KO
      const currentSceneName = krPanoScenes.find(
        (k) => k.building === currentSceneBuilding
      )
      const historiesByBuilding = targetHistories.filter(
        (h) => h.data.companyBuilding[0].languageMap.KO === currentSceneBuilding
      )
      const historiesGroup: HistoriesGroup[] = []
      historiesByBuilding.forEach((h) => {
        const year = h.data.startDate.KO?.substring(0, 4) as string
        if (historiesGroup.find((hg) => hg.year === year)) {
          historiesGroup.find((hg) => hg.year === year)?.items.push(h)
        } else {
          historiesGroup.push({
            year,
            items: [h],
          })
        }
      })

      // 정렬
      historiesGroup.sort((a, b) =>
        a.year > b.year ? 1 : b.year > a.year ? -1 : 0
      )

      historiesGroup.forEach((hg) => {
        hg.items.sort((a, b) =>
          // @ts-ignore
          a.data.startDate.KO > b.data.startDate.KO
            ? 1
            : // @ts-ignore
            b.data.startDate.KO > a.data.startDate.KO
            ? -1
            : 0
        )
      })

      historiesGroup.forEach((hg, hgIdx) => {
        hg.items.forEach((hgi, hgiIdx) => {
          if (hgi.uid === targetItem.uid) {
            historyGroupIdx = hgIdx
          }
        })
      })

      navigate(
        `?scene=${searchParams.get(
          'scene'
        )}&building=${currentSceneBuilding}&history=${historyGroupIdx}&uid=${
          targetItem.uid
        }`
      )
    } else {
      navigate(`?scene=${searchParams.get('scene')}&uid=${targetItem.uid}`)
    }

    setOpen(false)
  }

  /**
   * 관련 자료 열기
   */
  const openRelatedContents = () => {
    setOpen(!open)
    dispatch(getPreviousUrl(window.location.search))
  }

  return (
    <div
      className={`absolute left-0 bottom-0 related-contents ${
        open ? 'open' : ''
      }`}>
      <div className="pl-4 pb-2.5 btn-related-contents-container">
        <button
          type="button"
          className="btn btn-related-contents py-3 pr-10 pl-5 leading-4 bg-center font-medium"
          onClick={() => openRelatedContents()}>
          관련 자료
        </button>
      </div>
      <div className="related-contents-list">
        <div className="related-contents-list-body py-5 px-8 space-y-5">
          {item.data.relationHistories && item.data.relationHistories.length ? (
            <div>
              <h3 className="font-medium pb-1 border-b border-black">연혁</h3>
              <div className="">
                <ul className="">
                  {item.data.relationHistories.map((historyItem) => (
                    <li
                      key={historyItem}
                      className="border-b border-black"
                      onClick={() =>
                        openModal(
                          allContents.find((c) => c.uid === historyItem)
                        )
                      }>
                      {
                        allContents.find((c) => c.uid === historyItem)?.data
                          .title.KO
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
          {item.data.relationEpisodes && item.data.relationEpisodes.length ? (
            <div>
              <h3 className="font-medium pb-1 border-b border-black">
                에피소드
              </h3>
              <div className="">
                <ul className="">
                  {item.data.relationEpisodes.map((episodeItem) => (
                    <li
                      key={episodeItem}
                      className="border-b border-black"
                      onClick={() =>
                        openModal(
                          allContents.find((c) => c.uid === episodeItem)
                        )
                      }>
                      {
                        allContents.find((c) => c.uid === episodeItem)?.data
                          .title.KO
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
          {item.data.relationProducts && item.data.relationProducts.length ? (
            <div>
              <h3 className="font-medium pb-1 border-b border-black">제품</h3>
              <div className="">
                <ul className="">
                  {item.data.relationProducts.map((productItem) => (
                    <li
                      key={productItem}
                      className="border-b border-black"
                      onClick={() =>
                        openModal(
                          allContents.find((c) => c.uid === productItem)
                        )
                      }>
                      {
                        allContents.find((c) => c.uid === productItem)?.data
                          ?.nameKor?.KO
                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="related-contents-bg" onClick={() => setOpen(!open)}></div>
    </div>
  )
}

export default ModalRelatedContents
