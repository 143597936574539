import { ProductsItem } from '@/types'
import React from 'react'
import DateFormat from '../DateFormat'
import Images from '../Images'
import { Youtubes } from '../Youtubes'

export default function ModalContentsProduct({ item }: { item: ProductsItem }) {
  return item ? (
    <div>
      <div>
        {item.data.nameKor && item.data.nameKor.KO ? (
          <p
            className="!text-2xl leading-base"
            dangerouslySetInnerHTML={{
              __html: item.data.nameKor.KO.replace(/\n/g, '<br />'),
            }}
          />
        ) : (
          <></>
        )}
        {item.data.nameEng && item.data.nameEng.KO ? (
          <p
            className="!text-2xl leading-base"
            dangerouslySetInnerHTML={{
              __html: item.data.nameEng.KO.replace(/\n/g, '<br />'),
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <div className="mb-0.5 color-gray-alt text-2xl leading-base">
        {item.data.releaseDate && item.data.releaseDate.KO ? (
          <DateFormat str={item.data.releaseDate.KO} />
        ) : (
          <></>
        )}{' '}
        출시
      </div>
      {item.data.content && item.data.content.KO ? (
        <div className="mt-10">
          <p
            className="leading-lg"
            dangerouslySetInnerHTML={{
              __html: item.data.content.KO.replace(/\n/g, '<br />'),
            }}></p>
        </div>
      ) : (
        <></>
      )}
      {item.data.relationImages && item.data.relationImages.length ? (
        <div className="mt-10">
          <ul className="space-y-20">
            <Images images={item.data.relationImages} noGutter />
          </ul>
        </div>
      ) : (
        <></>
      )}
      {item.data.relationVideos && item.data.relationVideos.length ? (
        <div className="mt-10">
          <ul className="space-y-20">
            <Youtubes videos={item.data.relationVideos} />
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
