import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Masonry from 'react-masonry-css'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import SearchFilters from '@/components/Search/SearchFilters'
import SearchItem from '@/components/Search/SearchItem'
import SearchNotFound from '@/components/Search/SearchNotFound'
import { setFilters } from '@/states/actions/contents.actions'
import SearchEra from '@/components/Search/SearchEra'
import { toggleArchive } from '@/states/actions/layout.actions'

const SearchModal = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { layoutState, contentsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { archive } = layoutState
  const {
    loaded,
    filteredHistories,
    filteredEpisodes,
    filteredProducts,
    filteredResources,
    filter,
  } = contentsState

  // Memo
  const fileredContents = useMemo(() => {
    const contents = !filter.model
      ? [
          ...filteredHistories,
          ...filteredEpisodes,
          ...filteredProducts,
          ...filteredResources,
        ]
      : filter.model === 'histories'
      ? filteredHistories
      : filter.model === 'episodes'
      ? filteredEpisodes
      : filter.model === 'products'
      ? filteredProducts
      : filter.model === 'resources'
      ? filteredResources
      : []

    // 정렬
    contents.sort((a, b) =>
      a.data.sortName > b.data.sortName
        ? 1
        : b.data.sortName > a.data.sortName
        ? -1
        : 0
    )

    contents.sort((b, a) =>
      b.data.sortDate > a.data.sortDate
        ? 1
        : a.data.sortDate > b.data.sortDate
        ? -1
        : 0
    )

    return contents
  }, [
    filteredHistories,
    filteredEpisodes,
    filteredProducts,
    filteredResources,
    filter.model,
  ])

  // Effect
  useEffect(() => {
    if (loaded) {
      document
        .getElementById('search-modal')
        ?.addEventListener('scroll', setSearchLayout)
    }
  }, [loaded])

  /**
   * 검색 레이아웃 수정
   * @returns
   */
  const setSearchLayout = () => {
    // Top 버튼 변경
    const searchModal = document.getElementById('search-modal')
    const main = document.getElementById('search-modal')
    if (!searchModal || !main) return

    if (main.scrollTop) {
      searchModal.classList.add('expand')
    } else {
      searchModal.classList.remove('expand')
    }

    // 현재 시대 적용
    const searchEra = document.getElementById('search-era')
    if (!searchEra) return

    if (main.scrollTop) {
      searchEra.classList.add('shadow')
    } else {
      searchEra.classList.remove('shadow')
    }

    const cols = document.querySelectorAll('.masonry-grid-column')
    let currentEra: number | string | null = 1940

    cols.forEach((col) => {
      const colItems = col.querySelectorAll('.search-item')

      colItems.forEach((colItem) => {
        const top = colItem.getBoundingClientRect().top
        const era = colItem.getAttribute('data-era')
          ? Number(colItem.getAttribute('data-era'))
          : ''

        // 정지
        if (top < 0 && currentEra && era) {
          currentEra = currentEra < era ? era : currentEra
          return false
        }
      })
    })

    const eraItems = document.querySelectorAll('.search-era-item')
    eraItems.forEach((eraItem) => {
      const eraNav = eraItem.getAttribute('data-era-nav')
        ? Number(eraItem.getAttribute('data-era-nav'))
        : ''

      if (eraNav === currentEra) {
        eraItem.classList.add('color-black', 'border-black')
        eraItem.classList.remove('color-gray')
      } else {
        eraItem.classList.remove('color-black', 'border-black')
        eraItem.classList.add('color-gray')
      }
    })
  }

  /**
   * Top 으로 이동
   */
  const goTop = () => {
    const main = document.getElementById('search-modal')

    if (!main) return false

    main.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  /**
   * 아카이브 검색 닫기
   */
  const closeSearch = () => {
    dispatch(setFilters('', '', ''))
    dispatch(toggleArchive(false))
  }

  /**
   * Masonry options
   */
  const breakpointColumnsObj = {
    default: 5,
    1840: 4,
    1519: 3,
    1148: 2,
    776: 1,
    442: 1,
  }

  return (
    <>
      <div
        id="search-modal"
        className={`search-modal  ${archive ? 'open' : ''}`}>
        <div className={``}>
          <div className="sticky top-0 flex justify-between py-2 px-3 z-50">
            <div></div>
            <SearchEra />
            <button
              type="button"
              className="btn btn-line close btn-line-inline "
              onClick={() => closeSearch()}
              style={{
                zIndex: 2010,
              }}></button>
          </div>
          <div className="search-container px-8">
            {fileredContents.length ? (
              <>
                <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="masonry-grid space-x-8"
                  columnClassName="masonry-grid-column masonry-grid-column-sm">
                  {fileredContents.map((c, cIdx) => (
                    <SearchItem
                      item={c}
                      prevItem={fileredContents[cIdx - 1]}
                      nextItem={fileredContents[cIdx + 1]}
                      key={c.uid}
                      idx={cIdx}
                    />
                  ))}
                </Masonry>
                <div className="pt-20 pb-32 flex justify-center">
                  <button className="btn btn-to-top" onClick={() => goTop()}>
                    상단으로
                  </button>
                </div>
              </>
            ) : (
              <SearchNotFound />
            )}
          </div>
        </div>
      </div>
      {archive ? <SearchFilters /> : <></>}
      <div
        className={`search-modal-bg ${archive ? '' : 'hidden'}`}
        onClick={() => closeSearch()}></div>
    </>
  )
}

export default SearchModal
