import React, { useEffect, useMemo, useState } from 'react'
import { MediaInterface } from '@/types'
import { youtubeParser } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch } from '@/states/store'
import { toggleBg } from '@/states/actions/layout.actions'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'

export const YoutubePlayer = ({ item }: { item: MediaInterface }) => {
  const dispatch = useAppDispatch()

  // Memo
  const youtubeId = useMemo(() => {
    return youtubeParser(item.value)
  }, [item])

  // State (Redux)
  const { layouteState } = useSelector(
    (state: RootState) => ({
      layouteState: state.layout,
    }),
    shallowEqual
  )
  const { bg } = layouteState

  // State
  const uid = uuidv4()
  const [playing, setPlaying] = useState<boolean>(false)

  useEffect(() => {
    if (!item || !youtubeId) return

    var player
    function onYouTubeIframeAPIReady() {
      // @ts-ignore
      player = new YT.Player(`youtube-player-${uid}`, {
        height: '360',
        width: '640',
        videoId: youtubeId,
        events: {
          onStateChange: onPlayerStateChange,
        },
        origin: 'http://localhost:3000',
      })
    }

    /**
     * 영상 재생 확인
     * @param event
     */
    function onPlayerStateChange(event) {
      setPlaying(bg)

      // @ts-ignore
      if (event.data === YT.PlayerState.PLAYING) {
        dispatch(toggleBg(false))
      }
    }

    onYouTubeIframeAPIReady()
  }, [item, youtubeId])

  return (
    <div className="video-container">
      <div id={`youtube-player-${uid}`} className="youtube-player"></div>
    </div>
  )
}
