import {
  LayoutState,
  ActionTypesLayout,
  ActionsLayout,
} from '@/states/interfaces'

export const initialState: LayoutState = {
  nav: false,
  archive: false,
  guide: true,
  bg: false,
  previousUrl: '',
}

interface HydratePayload {
  layout: LayoutState
}

const layoutReducer = (
  state = initialState,
  action: ActionsLayout
): LayoutState => {
  switch (action.type) {
    // Nav Toggle
    case ActionTypesLayout.TOGGLE_NAV: {
      return {
        ...state,
        nav: action.payload.nav,
      }
    }
    // Archive search modal Toggle
    case ActionTypesLayout.TOGGLE_ARCHIVE: {
      return {
        ...state,
        archive: action.payload.archive,
      }
    }
    // 가이드 여닫기
    case ActionTypesLayout.TOGGLE_GUIDE: {
      return {
        ...state,
        guide: action.payload.guide,
      }
    }
    // BG 켜기/끄기
    case ActionTypesLayout.TOGGLE_BG: {
      return {
        ...state,
        bg: action.payload.bg,
      }
    }
    // 이전 URL 가져오기
    case ActionTypesLayout.GET_PREVIOUS_URL: {
      return {
        ...state,
        previousUrl: action.payload.url,
      }
    }
    default:
      return state
  }
}

export default layoutReducer
