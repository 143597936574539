import React from 'react'
import { useNavigate } from 'react-router'
import { neighborContents } from '@/configs'
import {
  EpisodesItem,
  HistoriesItem,
  ProductsItem,
  vArchiveItem,
  RndMediaItem,
} from '@/types'
import { useSearchParams } from 'react-router-dom'
import ModalRelatedContents from './ModalRelatedContents'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

export default function ModalContentsFooter({
  item,
}: {
  item:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | vArchiveItem
    | RndMediaItem
}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layouteState } = useSelector(
    (state: RootState) => ({
      layouteState: state.layout,
    }),
    shallowEqual
  )

  const { previousUrl } = layouteState

  /**
   * 콘텐츠 변경
   * @param uid
   */
  const changeContents = (uid) => {
    navigate(
      searchParams.get('scene')
        ? `?scene=${searchParams.get('scene')}&uid=${uid}`
        : `?uid=${uid}`
    )
  }

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = () => {
    if (previousUrl) {
      navigate(previousUrl)
      dispatch(getPreviousUrl(''))
    } else {
      navigate(
        searchParams.get('scene') ? `?scene=${searchParams.get('scene')}` : ''
      )
    }
  }

  return (
    <div className="py-1.5 ">
      <div className="flex justify-between items-end">
        <div className="w-full">
          {(item.data.relationHistories &&
            item.data.relationHistories.length) ||
          (item.data.relationEpisodes && item.data.relationEpisodes.length) ||
          (item.data.relationProducts && item.data.relationProducts.length) ? (
            <ModalRelatedContents
              item={item as HistoriesItem | EpisodesItem | ProductsItem}
            />
          ) : (
            <></>
          )}

          {/* <div className="flex">
            {item.data.relationHistories && item.data.relationHistories[0] ? (
              <div
                className="btn btn-pill bg-white cursor-pointer"
                onClick={() =>
                  navigate('?uid=' + (item.data.relationHistories[0] as string))
                }>
                관련 연혁
              </div>
            ) : (
              <></>
            )}
            {item.data.relationEpisodes && item.data.relationEpisodes[0] ? (
              <div
                className="btn btn-pill bg-light-green cursor-pointer"
                onClick={() =>
                  changeContents(
                    '?uid=' + (item.data.relationEpisodes[0] as string)
                  )
                }>
                관련 에피소드
              </div>
            ) : (
              <></>
            )}
            {item.data.relationProducts && item.data.relationProducts[0] ? (
              <div
                className="btn btn-pill bg-light-blue cursor-pointer"
                onClick={() =>
                  changeContents(
                    '?uid=' + (item.data.relationProducts[0] as string)
                  )
                }>
                관련 제품
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </div>
        <div className="px-4">
          <button
            type="button"
            className="btn btn-line btn-line-inline close flex-none"
            onClick={() => closeModal()}></button>
        </div>
      </div>
    </div>
  )
}
