import Layout from '@/components/Layout'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'

const transitionDuration = 1000

const Home = () => {
  const navigate = useNavigate()

  // Effect
  useEffect(() => {
    setTimeout(() => {
      AOS.init({})

      setTimeout(() => {
        // @ts-ignore
        sliderRef.current?.slickPlay()
      }, 3000)
    }, 6000)
  }, [])

  // Ref
  const sliderRef = useRef()

  // State
  const [openReload, setOpenReload] = useState<boolean>(false)
  const [closeReload, setCloseReload] = useState<boolean>(false)
  const [hideReload, setHideReload] = useState<boolean>(false)

  /**
   * 입장하기
   */
  const enter = () => {
    const audioEnter = document.getElementById(
      'audio-enter'
    ) as HTMLAudioElement
    if (audioEnter) {
      audioEnter.play()
    }

    setOpenReload(true)

    setTimeout(() => {
      navigate('/vr/충무로?scene=scene__ecpdsadhc_0217-1')
    }, transitionDuration)
  }

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 6000,
    autoplaySpeed: 12000,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div className="slick-dot w-1.5 h-1.5 bg-white rounded-full" />
    ),
    /* beforeChange: () => {
      const texts = document.querySelectorAll('.text-line')
      texts.forEach((text) => {
        text.classList.remove('line')
      })
    },
    afterChange: () => {
      const texts = document.querySelectorAll('.text-line')
      texts.forEach((text) => {
        text.classList.add('line')
      })
    }, */
  }

  return (
    <Layout>
      <div className="w-full h-screen flex justify-center items-end">
        <div className="intro-bg">
          <video playsInline autoPlay muted loop poster="/assets/intro.png">
            <source
              src={
                process.env.REACT_APP_ASSETS + '/assets/intro_h_23032901.webp'
              }
              type="video/webp"
            />
            <source
              src={
                process.env.REACT_APP_ASSETS + '/assets/intro_h_23032901.mp4'
              }
              type="video/mp4"
            />
          </video>
        </div>
        <div
          className="intro-contents"
          data-aos="fade-up"
          data-aos-duration="3000">
          <div className="flex justify-center mb-[30px]">
            <div className="logo logo-jw-archive-intro w-[140px] md:w-[180px] h-[76px] md:h-[98px]"></div>
          </div>
          <div className="intro-slider">
            <Slider ref={sliderRef} {...settings}>
              <div className="intro-para text animation">
                <div className="line2 text-line">
                  JW는 1945년 돛을 올리고 출항한 후로 한 번도 쉬지 않고 드넓은
                  바다를 항해하고 있습니다. 긴 여정 속에서 우리는 소중한 생명을
                  지키고 사회적 책임을 다하기 위해 끊임없이 도전하고 혁신을
                  거듭해왔습니다.
                </div>
              </div>
              <div className="intro-para text animation">
                <div className="line2 text-line">
                  도전과 혁신의 기록은 현재의 우리를 있게 한 밑거름이자, 미래를
                  향해 나아갈 수 있게 하는 동력입니다. 끝이 보이지 않는
                  망망대해에서 멋진 미래를 향해 전진하고 있는 JW의 기나긴 여정에
                  함께 하시겠습니까?
                </div>
              </div>
            </Slider>
          </div>
          <div className="flex justify-center">
            <div className="btn btn-enter" onClick={() => enter()}>
              입장하기
            </div>
          </div>
        </div>
      </div>
      <div
        className={`vr-reload ${openReload ? 'open' : ''} ${
          closeReload ? 'close' : ''
        } ${hideReload ? 'hide' : ''}`}>
        <div className="vr-reload-logo"></div>
      </div>
      <audio
        id="audio-enter"
        src={process.env.REACT_APP_ASSETS + '/assets/audio-enter.mp3'}
        preload=""></audio>
    </Layout>
  )
}

export default Home
