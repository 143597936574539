import { combineReducers, Reducer, AnyAction } from 'redux'
import { RootStateInterface } from '@/states/interfaces/rootState'

import layoutReducer from './layout.reducer'
import contentsReducer from './contents.reducer'

const rootReducer: Reducer<RootStateInterface, AnyAction> =
  combineReducers<RootStateInterface>({
    layout: layoutReducer,
    contents: contentsReducer,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
