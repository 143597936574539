import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { HistoriesGroup, HistoriesItem, JwBuildingsType } from '@/types'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

export default function ModalHistoriesContentsFooter({
  idx,
  len,
  item,
  curentBuilding,
}: {
  idx: number
  len: number
  item: HistoriesItem
  curentBuilding: JwBuildingsType
}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { contentsState, layoutState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { histories } = contentsState
  const { previousUrl } = layoutState

  // 연혁 그룹
  const historiesGroup: HistoriesGroup[] = useMemo(() => {
    const historiesByBuilding = histories.filter(
      (h) => h.data.companyBuilding[0].languageMap.KO === curentBuilding
    )
    const historiesGroup: HistoriesGroup[] = []

    historiesByBuilding.forEach((h) => {
      const year = h.data.startDate.KO?.substring(0, 4) as string
      if (historiesGroup.find((hg) => hg.year === year)) {
        historiesGroup.find((hg) => hg.year === year)?.items.push(h)
      } else {
        historiesGroup.push({
          year,
          items: [h],
        })
      }
    })

    // 정렬
    historiesGroup.sort((a, b) =>
      a.year > b.year ? 1 : b.year > a.year ? -1 : 0
    )

    historiesGroup.forEach((hg) => {
      hg.items.sort((a, b) =>
        // @ts-ignore
        a.data.startDate.KO > b.data.startDate.KO
          ? 1
          : // @ts-ignore
          b.data.startDate.KO > a.data.startDate.KO
          ? -1
          : 0
      )
    })

    return historiesGroup
  }, [histories, curentBuilding])

  /**
   * 콘텐츠 변경
   * @param uid
   */
  const changeContents = (history) => {
    const initUid = historiesGroup[history].items[0].uid

    navigate(
      searchParams.get('scene')
        ? `?scene=${searchParams.get('scene')}&building=${searchParams.get(
            'building'
          )}&history=${history}&uid=${initUid}`
        : `?uid=${initUid}`
    )

    setTimeout(() => {
      const currentHistoriesItem = document.querySelector(
        '.modal-histories-item.current'
      )
      const currentModalBody =
        currentHistoriesItem?.querySelector('.modal-body')

      if (currentModalBody) {
        currentModalBody.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
    })
  }

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = () => {
    if (previousUrl) {
      navigate(previousUrl)
      dispatch(getPreviousUrl(''))
    } else {
      navigate(
        searchParams.get('scene') ? `?scene=${searchParams.get('scene')}` : ''
      )
    }
  }

  return (
    <div className="py-1.5 px-4">
      <div className="flex justify-between items-end">
        <div className="w-full">
          <div className="flex space-x-10">
            {idx > 0 ? (
              <button
                type="button"
                className="btn btn-line btn-line-inline-long arrow-left"
                onClick={() => changeContents(idx - 1)}></button>
            ) : (
              <button
                type="button"
                className="btn btn-line btn-line-inline-long arrow-left opacity-10 cursor-not-allowed"></button>
            )}
            {idx < len - 1 ? (
              <button
                type="button"
                className="btn btn-line btn-line-inline-long arrow-right"
                onClick={() => changeContents(idx + 1)}></button>
            ) : (
              <button
                type="button"
                className="btn btn-line btn-line-inline-long arrow-right opacity-10 cursor-not-allowed"></button>
            )}
          </div>
        </div>
        <button
          type="button"
          className="btn btn-line btn-line-inline close flex-none"
          onClick={() => closeModal()}></button>
      </div>
    </div>
  )
}
