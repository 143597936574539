import {
  ContentsState,
  ActionTypesContents,
  ActionsContents,
} from '@/states/interfaces'

export const initialState: ContentsState = {
  loaded: false,
  importantHistories: [],
  histories: [],
  filteredHistories: [],
  episodes: [],
  filteredEpisodes: [],
  products: [],
  filteredProducts: [],
  resources: [],
  filteredResources: [],
  filter: {
    keyword: '',
    tag: '',
    model: '',
  },
  vArchives: [],
  rndMedia: [],
  images: [],
  currentImage: null,
}

interface HydratePayload {
  contents: ContentsState
}

const contentsReducer = (
  state = initialState,
  action: ActionsContents
): ContentsState => {
  switch (action.type) {
    // Set Loaded
    case ActionTypesContents.SET_LOADED: {
      return {
        ...state,
        loaded: action.payload.loaded,
      }
    }
    // 연혁 목록 설정
    case ActionTypesContents.SET_HISTORIES_LIST: {
      const list = action.payload.list

      list.forEach((i) => {
        i.data.contentsType = 'histories'
        i.data.sortDate = i.data.startDate
          ? (i.data.startDate.KO as string)
          : ''
        i.data.sortName = i.data.title ? (i.data.title.KO as string) : ''

        if (i.data.startDate) {
          i.data.startDate.KO = i.data.startDate.KO?.padEnd(8, '9')
        }

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []

        // 관련 영상 + 이미지
        i.data.relationMedias = [
          ...(i.data.relationImages ? i.data.relationImages : []),
          ...(i.data.relationVideos ? i.data.relationVideos : []),
        ]
      })

      list.sort((a, b) =>
        // @ts-ignore
        a.data.startDate.KO > b.data.startDate?.KO
          ? 1
          : // @ts-ignore
          b.data.startDate?.KO > a.data.startDate?.KO
          ? -1
          : 0
      )

      return {
        ...state,
        histories: list.filter(
          (item) =>
            item.data.isPublic &&
            (item.data.importance[0].languageMap.KO === '1' ||
              item.data.importance[0].languageMap.KO === '2' ||
              item.data.importance[0].languageMap.KO === '3' ||
              item.data.importance[0].languageMap.KO === '4' ||
              item.data.importance[0].languageMap.KO === '5')
        ),
        filteredHistories: list.filter(
          (item) =>
            item.data.isPublic &&
            (item.data.importance[0].languageMap.KO === '1' ||
              item.data.importance[0].languageMap.KO === '2' ||
              item.data.importance[0].languageMap.KO === '3' ||
              item.data.importance[0].languageMap.KO === '4' ||
              item.data.importance[0].languageMap.KO === '5')
        ),
        importantHistories: list.filter(
          (item) =>
            item.data.isPublic &&
            (item.data.importance[0].languageMap.KO === '1' ||
              item.data.importance[0].languageMap.KO === '2' ||
              item.data.importance[0].languageMap.KO === '3')
        ),
      }
    }
    // 에피소드 목록 설정
    case ActionTypesContents.SET_EPISODES_LIST: {
      const list = action.payload.list.filter((item) => item.data.isPublic)

      list.forEach((i) => {
        i.data.contentsType = 'episodes'
        i.data.sortDate = i.data.startDate
          ? (i.data.startDate.KO as string)
          : ''
        i.data.sortName = i.data.title ? (i.data.title.KO as string) : ''

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []

        // 관련 영상 + 이미지
        i.data.relationMedias = [
          ...(i.data.relationImages ? i.data.relationImages : []),
          ...(i.data.relationVideos ? i.data.relationVideos : []),
        ]
      })

      return {
        ...state,
        episodes: list,
        filteredEpisodes: list,
      }
    }
    // 제품 목록 설정
    case ActionTypesContents.SET_PRODUCTS_LIST: {
      const list = action.payload.list.filter((item) => item.data.isPublic)

      list.forEach((i) => {
        i.data.contentsType = 'products'
        i.data.sortDate = i.data.releaseDate
          ? (i.data.releaseDate.KO as string)
          : ''
        i.data.sortName = i.data.nameKor ? (i.data.nameKor.KO as string) : ''

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []

        // 관련 영상 + 이미지
        i.data.relationMedias = [
          ...(i.data.relationImages ? i.data.relationImages : []),
          ...(i.data.relationVideos ? i.data.relationVideos : []),
        ]
      })

      return {
        ...state,
        products: list,
        filteredProducts: list,
      }
    }
    // 미디어 목록 설정
    case ActionTypesContents.SET_RESOURCES_LIST: {
      const list = action.payload.list.filter((item) => item.data.isPublic)

      list.forEach((i) => {
        i.data.contentsType = 'resources'
        i.data.sortDate = i.data.startDate
          ? (i.data.startDate.KO as string)
          : ''
        i.data.sortName = i.data.title ? (i.data.title.KO as string) : ''

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []

        // 관련 영상 + 이미지
        i.data.relationMedias = [
          ...(i.data.relationImages ? i.data.relationImages : []),
          ...(i.data.relationVideos ? i.data.relationVideos : []),
        ]
      })

      return {
        ...state,
        resources: list,
        filteredResources: list,
      }
    }
    // 버추얼아카이브 목록 설정
    case ActionTypesContents.SET_VARCHIVES_LIST: {
      const list = action.payload.list

      list.forEach((i) => {
        i.data.contentsType = 'vArchive'

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []

        // 관련 영상 + 이미지
        i.data.relationMedias = [
          ...(i.data.relationImages ? i.data.relationImages : []),
          ...(i.data.relationVideos ? i.data.relationVideos : []),
        ]
      })

      return {
        ...state,
        vArchives: list,
      }
    }
    // R&D (과천 미디어) 목록 설정
    case ActionTypesContents.SET_RNDMEDIA_LIST: {
      const list = action.payload.list

      list.forEach((i) => {
        i.data.contentsType = 'rndMedia'

        i.data.relationVideos = i.data.relationVideos
          ? i.data.relationVideos.filter((item) => item.mediaType === 'URL')
          : []
      })

      return {
        ...state,
        rndMedia: list,
      }
    }
    // 필터 설정
    case ActionTypesContents.SET_FILTERS: {
      const filter = action.payload.filter
      const keyword = filter.keyword
      const tag = filter.tag
      const model = filter.model
      let filteredHistories = state.histories
      let filteredEpisodes = state.episodes
      let filteredProducts = state.products
      let filteredResources = state.resources

      if (keyword) {
        filteredHistories = filteredHistories.filter(
          (item) =>
            item.data.title.KO?.includes(keyword) ||
            item.data.author.KO?.includes(keyword)
        )
        filteredEpisodes = filteredEpisodes.filter(
          (item) =>
            item.data.title.KO?.includes(keyword) ||
            item.data.content.KO?.includes(keyword) ||
            item.data.era.KO?.includes(keyword) ||
            item.data.origin.KO?.includes(keyword) ||
            item.data.author.KO?.includes(keyword)
        )
        filteredProducts = filteredProducts.filter(
          (item) =>
            item.data.nameKor.KO?.includes(keyword) ||
            item.data.nameEng.KO?.includes(keyword) ||
            item.data.content.KO?.includes(keyword) ||
            item.data.ceo.KO?.includes(keyword) ||
            item.data.otherPerson.KO?.includes(keyword) ||
            item.data.author.KO?.includes(keyword)
        )
        filteredResources = filteredResources.filter(
          (item) =>
            item.data.title.KO?.includes(keyword) ||
            item.data.content.KO?.includes(keyword) ||
            item.data.author.KO?.includes(keyword)
        )
      }

      if (tag) {
        filteredHistories = filteredHistories.filter(
          (item) =>
            item.data.innovationTags &&
            item.data.innovationTags.find(
              (innovationTag) => innovationTag.languageMap.KO === tag
            )
        )
        filteredEpisodes = filteredEpisodes.filter(
          (item) =>
            item.data.innovationTags &&
            item.data.innovationTags.find(
              (innovationTag) => innovationTag.languageMap.KO === tag
            )
        )
        filteredProducts = filteredProducts.filter(
          (item) =>
            item.data.innovationTags &&
            item.data.innovationTags.find(
              (innovationTag) => innovationTag.languageMap.KO === tag
            )
        )
        filteredResources = filteredResources.filter(
          (item) =>
            item.data.innovationTags &&
            item.data.innovationTags.find(
              (innovationTag) => innovationTag.languageMap.KO === tag
            )
        )
      }

      return {
        ...state,
        filteredHistories,
        filteredEpisodes,
        filteredProducts,
        filteredResources,
        filter,
      }
    }
    // 사진 확대보기 목록 설정
    case ActionTypesContents.SET_IMAGES: {
      return {
        ...state,
        images: action.payload.images,
        currentImage: action.payload.currentImage,
      }
    }
    default:
      return state
  }
}

export default contentsReducer
