import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Masonry from 'react-masonry-css'
import Layout from '@/components/Layout'
import SearchFilters from '@/components/Search/SearchFilters'
import SearchItem from '@/components/Search/SearchItem'
import { RootState } from '@/states/reducers'
import { useNavigate } from 'react-router'
import SearchNotFound from '@/components/Search/SearchNotFound'
import { useAppDispatch } from '@/states/store'
import { setFilters } from '@/states/actions/contents.actions'
import SearchEra from '@/components/Search/SearchEra'
import { useSearchParams } from 'react-router-dom'

export default function Search() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const {
    loaded,
    filteredHistories,
    filteredEpisodes,
    filteredProducts,
    filteredResources,
    filter,
  } = contentsState

  // Memo
  const fileredContents = useMemo(() => {
    const contents = !filter.model
      ? [
          ...filteredHistories,
          ...filteredEpisodes,
          ...filteredProducts,
          ...filteredResources,
        ]
      : filter.model === 'histories'
      ? filteredHistories
      : filter.model === 'episodes'
      ? filteredEpisodes
      : filter.model === 'products'
      ? filteredProducts
      : filter.model === 'resources'
      ? filteredResources
      : []

    // 정렬
    contents.sort((a, b) =>
      a.data.sortName > b.data.sortName
        ? 1
        : b.data.sortName > a.data.sortName
        ? -1
        : 0
    )

    contents.sort((b, a) =>
      b.data.sortDate > a.data.sortDate
        ? 1
        : a.data.sortDate > b.data.sortDate
        ? -1
        : 0
    )

    return contents
  }, [
    filteredHistories,
    filteredEpisodes,
    filteredProducts,
    filteredResources,
    filter.model,
  ])

  // Effect
  useEffect(() => {
    if (loaded) {
      document
        .getElementById('main')
        ?.addEventListener('scroll', setSearchLayout)
    }
  }, [loaded])

  /**
   * 검색 레이아웃 수정
   * @returns
   */
  const setSearchLayout = () => {
    // Top 버튼 변경
    const btnTop = document.getElementById('btn-top')
    const main = document.getElementById('main')
    if (!btnTop || !main) return

    btnTop.style.display = main.scrollTop ? 'block' : 'none'

    // 현재 시대 적용
    const searchEra = document.getElementById('search-era')
    if (!searchEra) return

    if (main.scrollTop) {
      searchEra.classList.add('shadow')
    } else {
      searchEra.classList.remove('shadow')
    }

    const cols = document.querySelectorAll('.masonry-grid-column')
    let currentEra: number | string | null = 1940

    cols.forEach((col) => {
      const colItems = col.querySelectorAll('.search-item')

      colItems.forEach((colItem) => {
        const top = colItem.getBoundingClientRect().top
        const era = colItem.getAttribute('data-era')
          ? Number(colItem.getAttribute('data-era'))
          : ''

        // 정지
        if (top < 0 && currentEra && era) {
          currentEra = currentEra < era ? era : currentEra
          return false
        }
      })
    })

    const eraItems = document.querySelectorAll('.search-era-item')
    eraItems.forEach((eraItem) => {
      const eraNav = eraItem.getAttribute('data-era-nav')
        ? Number(eraItem.getAttribute('data-era-nav'))
        : ''

      if (eraNav === currentEra) {
        eraItem.classList.add('color-black', 'border-black')
        eraItem.classList.remove('color-gray')
      } else {
        eraItem.classList.remove('color-black', 'border-black')
        eraItem.classList.add('color-gray')
      }
    })
  }

  /**
   * Top 으로 이동
   */
  const goTop = () => {
    const main = document.getElementById('main')

    if (!main) return false

    main.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const closeSearch = () => {
    dispatch(setFilters('', '', ''))
    navigate(`/vr?scene=${searchParams.get('scene')}`)
  }

  /**
   * Masonry options
   */
  const breakpointColumnsObj = {
    default: 4,
    1440: 3,
    1024: 2,
    767: 1,
  }

  return (
    <Layout>
      <div
        className={`container mx-auto px-3 lg:px-0 ${
          fileredContents.length ? 'py-16 mt-2' : ''
        }`}>
        <div
          className="w-20 h-7 logo logo-jw-archive black fixed left-5 top-5 cursor-pointer hidden md:block"
          onClick={() => closeSearch()}
          style={{
            zIndex: 2010,
          }}></div>
        <button
          type="button"
          className="btn btn-close-block btn-pill btn-pill-inline bg-white fixed right-3 lg:right-4 top-3"
          onClick={() => closeSearch()}
          style={{
            zIndex: 2010,
          }}></button>
        <SearchEra />
        {fileredContents.length ? (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid-column masonry-grid-column-sm">
            {fileredContents.map((c, cIdx) => (
              <SearchItem
                item={c}
                prevItem={fileredContents[cIdx - 1]}
                nextItem={fileredContents[cIdx + 1]}
                key={c.uid}
                idx={cIdx}
              />
            ))}
          </Masonry>
        ) : (
          <SearchNotFound />
        )}
        <SearchFilters />
        <button
          type="button"
          id="btn-top"
          className="btn btn-top"
          onClick={() => goTop()}></button>
      </div>
    </Layout>
  )
}
