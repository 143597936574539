import React from 'react'
import { RndMediaItem, vArchiveItem } from '@/types'
import Images from '../Images'
import { Youtubes } from '../Youtubes'

export const ModalContentsRndMedia = ({ item }: { item: RndMediaItem }) => {
  return item ? (
    <div>
      <div className="text-2xl leading-base">
        {item.data.title && item.data.title.KO ? (
          <div
            dangerouslySetInnerHTML={{
              __html: item.data.title.KO.replace(/\n/g, '<br />'),
            }}></div>
        ) : (
          <></>
        )}
      </div>
      {item.data.content && item.data.content.KO ? (
        <div className="mt-10">
          <p
            className="leading-lg"
            dangerouslySetInnerHTML={{
              __html: item.data.content.KO.replace(/\n/g, '<br />'),
            }}></p>
        </div>
      ) : (
        <></>
      )}
      {item.data.contentImages &&
      item.data.contentImages.length &&
      item.data.contentsType === 'rndMedia' ? (
        <div className="mt-10">
          <ul className="space-y-20">
            <Images
              images={item.data.contentImages}
              isOrigin
              noCaption
              noZoom
            />
          </ul>
        </div>
      ) : (
        <></>
      )}
      {item.data.relationVideos && item.data.relationVideos.length ? (
        <div className="mt-10">
          <ul className="space-y-20">
            <Youtubes videos={item.data.relationVideos} />
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
