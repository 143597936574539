import React from 'react'
import {
  HistoriesItem,
  EpisodesItem,
  ProductsItem,
  ResourcesItem,
  JwBuildingsType,
  vArchiveItem,
  RndMediaItem,
} from '@/types'

/**
 * 사옥별 모달 헤더
 * @param param0
 * @returns
 */
export default function ModalContentsHeader({
  item,
  companyBuilding,
}: {
  item:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | ResourcesItem
    | vArchiveItem
    | RndMediaItem
  companyBuilding: JwBuildingsType
}) {
  return (
    <div
      className="pt-2.5 md:pt-2 px-4 font-ginto-nord font-medium text-xs"
      style={{
        lineHeight: '0.875rem',
      }}>
      {companyBuilding === '충무로' ? (
        <div className="modal-header-era flex items-center justify-between mb-0 space-x-2">
          <div className="flex-none">1945</div>
          <div className="w-full h-px -mt-px border-b border-black"></div>
          <div className="flex-none text-right">1966</div>
          <div className="flex-none text-right">1986</div>
          <div className="flex-none text-right">2012</div>
          <div className="flex-none text-right">2023</div>
        </div>
      ) : (
        <></>
      )}
      {companyBuilding === '하월곡' ? (
        <div className="modal-header-era flex items-center justify-between mb-0 space-x-2">
          <div className="flex-none">1945</div>
          <div className="flex-none">1966</div>
          <div className="w-full h-px -mt-px border-b border-black"></div>
          <div className="flex-none text-right">1986</div>
          <div className="flex-none text-right">2012</div>
          <div className="flex-none text-right">2023</div>
        </div>
      ) : (
        <></>
      )}
      {companyBuilding === '신대방' ? (
        <div className="modal-header-era flex items-center justify-between mb-0 space-x-2">
          <div className="flex-none">1945</div>
          <div className="flex-none">1966</div>
          <div className="flex-none">1986</div>
          <div className="w-full h-px -mt-px border-b border-black"></div>
          <div className="flex-none text-right">2012</div>
          <div className="flex-none text-right">2023</div>
        </div>
      ) : (
        <></>
      )}
      {companyBuilding === '서초' ? (
        <div className="modal-header-era flex items-center justify-between mb-0 space-x-2">
          <div className="flex-none">1945</div>
          <div className="flex-none">1966</div>
          <div className="flex-none">1986</div>
          <div className="flex-none">2012</div>
          <div className="w-full h-px -mt-px border-b border-black"></div>
          <div className="flex-none text-right">2023</div>
        </div>
      ) : (
        <></>
      )}
      {companyBuilding === '과천' ? (
        <div className="modal-header-era flex items-center justify-between mb-0 space-x-2">
          <div className="flex-none">1945</div>
          <div className="flex-none">1966</div>
          <div className="flex-none">1986</div>
          <div className="flex-none">2012</div>
          <div className="flex-none">2023</div>
          <div className="w-full h-px -mt-px border-b border-black"></div>
          <div className="flex-none text-right" />
        </div>
      ) : (
        <></>
      )}
      {item.data.contentsType !== 'rndMedia' &&
      item.data.contentsType !== 'vArchive' ? (
        <div className={`modal-contents-header-title`}>
          {
            // @ts-ignore
            item.data.sortDate.substring(0, 4)
          }
        </div>
      ) : (
        <div className={`modal-contents-header-title ${companyBuilding}`}></div>
      )}

      {item.data.contentsType !== 'episodes' &&
      (item.data.contentsType !== 'vArchive' ||
        item.data.code?.KO?.split('-')[2] === '01') ? (
        <div
          id="modal-contents-header-bg"
          className="modal-contents-header-bg"></div>
      ) : (
        <></>
      )}
    </div>
  )
}
