import React, { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    location.href = '/'
  })
  return <div></div>
}

export default NotFound
