import { CategoryInterface, LanguageMap } from '@/types'
import React from 'react'

export default function Tags({
  tags,
  color,
}: {
  tags: CategoryInterface[]
  color: string
}) {
  return (
    <ul className="flex items-center">
      {tags.map((tag, idx) => (
        <li
          key={idx}
          className={`px-2.5 h-6 rounded-xl text-sm bg-${color} flex items-center`}>
          <span>{tag.languageMap.KO}</span>
        </li>
      ))}
    </ul>
  )
}
