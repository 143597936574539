import axios from 'axios'
import { projectUid } from '@/configs'
import { ActionTypesContents } from '../interfaces'

/**
 * 데이터 불러오기
 * @returns
 */
export const getContentsDataList = (req, modelKey) => {
  return axios.post(
    `${process.env.REACT_APP_MEMEX_API}/projects/${projectUid}/models/${modelKey}/contents/search/v1`,
    req,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Token': process.env.REACT_APP_MEMEX_APP_KEY as string,
      },
    }
  )
}

/**
 * 로딩 설정
 * @param loaded
 */
export const setLoaded = (loaded: boolean) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_LOADED,
    payload: {
      loaded: loaded,
    },
  })
}

/**
 * 연혁 목록 설정
 */
export const setHistoriesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_HISTORIES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 에피소드 목록 설정
 */
export const setEpisodesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_EPISODES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 제품 목록 설정
 */
export const setProductsList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_PRODUCTS_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 미디어 목록 설정
 */
export const setResourcesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_RESOURCES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 버추얼아카이브 목록 설정
 */
export const setvArchivesList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_VARCHIVES_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * R&D (과천 미디어) 목록 설정
 */
export const setRndMediaList = (data) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_RNDMEDIA_LIST,
    payload: {
      list: data,
    },
  })
}

/**
 * 필터 설정
 */
export const setFilters = (keyword, tag, model) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_FILTERS,
    payload: {
      filter: {
        keyword,
        tag,
        model,
      },
    },
  })
}

/**
 * 사진 확대보기 목록 설정
 */
export const setImagesList = (images, currentImage) => (dispatch) => {
  return dispatch({
    type: ActionTypesContents.SET_IMAGES,
    payload: {
      images,
      currentImage,
    },
  })
}
