import { jwModels, jwTags } from '@/configs'
import { setFilters } from '@/states/actions/contents.actions'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function SearchFilters() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [showTag, setShowTag] = useState<boolean>(false)
  const [showModel, setShowModel] = useState<boolean>(false)
  const [expandKeyword, setExpandKeyword] = useState<boolean>(false)
  const [showMobile, setShowMobile] = useState<boolean>(false)
  const [showMobileTag, setShowMobileTag] = useState<boolean>(true)
  const [showMobileKeyword, setShowMobileKeyword] = useState<boolean>(false)
  const [filterKeyword, setFilterKeyword] = useState<string>('')
  const [filterTag, setFilterTag] = useState<string>('')
  const [filterModel, setFilterModel] = useState<string>('')

  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { loaded, histories, episodes, products, filter } = contentsState

  // Effect
  useEffect(() => {
    if (searchParams.get('clear')) {
      onClear()
    }
  }, [searchParams.get('clear')])

  /**
   * 검색명 입력
   * @param e
   */
  const onHandleKeywordSubmit = (e) => {
    e.preventDefault()
    setExpandKeyword(false)
    setFilterTag('')
    setFilterModel('')
    dispatch(setFilters(filterKeyword, '', ''))

    document.getElementById('q')?.blur()
    // navigate('/search')

    document.getElementById('main')?.scrollTo({
      top: 0,
      left: 0,
    })
  }

  /**
   * 태그 선택
   * @param value
   */
  const onHandleSelectTag = (value) => {
    setFilterTag(value)
    setFilterKeyword('')
    setShowTag(false)
    setShowMobileTag(false)
    setShowMobileKeyword(false)
    setShowMobile(false)
    dispatch(setFilters('', value, filter.model))
    // navigate('/search')

    document.getElementById('main')?.scrollTo({
      top: 0,
      left: 0,
    })
  }

  /**
   * 모델 선택
   * @param value
   */
  const onHandleSelectModel = (value) => {
    setFilterModel(value)
    setFilterKeyword('')
    setShowModel(false)
    setShowMobileTag(false)
    setShowMobileKeyword(false)
    setShowMobile(false)
    dispatch(setFilters('', filter.tag, value))
    // navigate('/search')

    document.getElementById('main')?.scrollTo({
      top: 0,
      left: 0,
    })
  }

  /**
   * 필터 초기화
   */
  const onClear = () => {
    setShowTag(false)
    setShowModel(false)
    setExpandKeyword(false)
    setShowMobile(false)
    setShowMobileTag(true)
    setShowMobileKeyword(false)
    setFilterKeyword('')
    setFilterTag('')
    setFilterModel('')
    dispatch(setFilters('', '', ''))
  }

  return (
    <div className="search-filters-container">
      <div
        className={`search-filters flex justify-between text-sm relative ${
          expandKeyword ? 'expand-keyword' : ''
        } ${showMobile ? 'show-mobile' : ''}`}>
        {showModel || showTag || showMobile ? (
          <div
            className="search-filters-bg"
            onClick={() => {
              setShowTag(false)
              setShowModel(false)
              setShowMobile(false)
            }}></div>
        ) : (
          <></>
        )}
        <div className="search-filters-nav lg:hidden w-full h-full z-10">
          {showMobile ? (
            <div className="w-full h-full flex justify-center items-center space-x-2">
              <div
                className={`py-2 px-4 cursor-pointer leading-5 text-sm rounded-2xl ${
                  showMobileTag ? 'bg-shadow-inner' : ''
                }`}
                onClick={() => {
                  setShowMobileTag(true)
                  setShowMobileKeyword(false)
                  setShowModel(false)
                  setShowTag(false)
                }}>
                태그 검색
              </div>
              <div
                className={`py-2 px-4 cursor-pointer leading-5 text-sm rounded-2xl ${
                  showMobileKeyword ? 'bg-shadow-inner' : ''
                }`}
                onClick={() => {
                  setShowMobileTag(false)
                  setShowMobileKeyword(true)
                  setShowModel(false)
                  setShowTag(false)
                }}>
                키워드 검색
              </div>
            </div>
          ) : filter.keyword || filter.model || filter.tag ? (
            <div
              className="w-2/3 h-full flex justify-center items-center"
              style={{
                paddingLeft: '33%',
              }}
              onClick={() => {
                setShowMobile(!showMobile)
                setShowTag(false)
                setShowModel(false)
              }}>
              <div className="w-full h-full flex justify-center items-center space-x-2 left-0 relative">
                <div className="w-20 truncate">
                  {filter.keyword ? filter.keyword : ''}
                  {filter.tag ? filter.tag : !filter.keyword ? '전체 태그' : ''}
                  {filter.model || filter.tag
                    ? !filter.keyword
                      ? ', '
                      : ''
                    : ''}
                  {filter.model
                    ? jwModels.find((m) => m.modelKey === filterModel)?.label
                    : !filter.keyword
                    ? '전체 유형'
                    : ''}
                </div>
              </div>
              <button
                type="button"
                className="flex-none btn btn-refresh w-4 h-4"
                onClick={() => onClear()}></button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`search-filters-keyword flex-none flex lg:items-center ${
            showMobile && showMobileKeyword ? 'show-mobile' : ''
          }`}>
          <form
            onSubmit={onHandleKeywordSubmit}
            className="w-full flex justify-between lg:items-center lg:pr-6">
            <div className="w-full">
              <input
                type="text"
                id="q"
                className="form-input search truncate"
                value={filterKeyword}
                onChange={(e) => setFilterKeyword(e.target.value)}
                onFocus={() => setExpandKeyword(true)}
                onBlur={() => setExpandKeyword(false)}
                placeholder="키워드 검색"
              />
              <button
                type="button"
                className="btn absolute top-4 left-5 w-10 h-12 bg-transparent"
                onClick={onHandleKeywordSubmit}></button>
              {filter.keyword ? (
                <div className="flex justify-center pt-4 lg:pt-0 lg:hidden">
                  <button
                    type="button"
                    className="btn btn-clear"
                    onClick={() => onClear()}>
                    초기화
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            {expandKeyword ? (
              <button
                type="button"
                className="btn btn-close btn-close-white w-3 h-3 absolute right-9 top-9 -mt-0.5 lg:relative lg:right-auto lg:top-auto lg:mt-0"
                onClick={() => {
                  setShowMobile(false)
                  setFilterKeyword('')
                  setExpandKeyword(false)
                  setShowMobileTag(true)
                  setShowMobileKeyword(false)
                  dispatch(setFilters('', '', ''))
                }}></button>
            ) : (
              <></>
            )}
            {showMobileKeyword ? (
              <button
                type="button"
                className="btn btn-close btn-close-white w-3 h-3 absolute right-9 top-9 -mt-0.5 lg:relative lg:right-auto lg:top-auto lg:mt-0"
                onClick={() => {
                  setShowMobile(false)
                  setExpandKeyword(false)
                  setShowMobileTag(true)
                  setShowMobileKeyword(false)
                }}></button>
            ) : (
              <></>
            )}
          </form>
        </div>
        <div
          className={`search-filters-tags w-full pl-5 pr-5 pt-4 lg:pt-0 lg:flex lg:justify-between lg:items-center ${
            showMobile && showMobileTag ? 'show-mobile' : ''
          }`}>
          <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 items-center">
            <p className="pr-3">JW의</p>
            <div className="pr-3 flex items-center space-x-1">
              <button
                type="button"
                className={`btn btn-select ${showTag ? 'open' : ''}`}
                onClick={() => {
                  setShowModel(false)
                  setShowTag(!showTag)
                }}>
                {filterTag ? filterTag : '전체 태그'}
              </button>
              <p className="">에 관련된</p>
            </div>
            <div className="flex items-center space-x-1 pr-5">
              <button
                type="button"
                className={`btn btn-select ${showModel ? 'open' : ''}`}
                onClick={() => {
                  setShowTag(false)
                  setShowModel(!showModel)
                }}>
                {filterModel
                  ? jwModels.find((m) => m.modelKey === filterModel)?.label
                  : '전체 유형'}
              </button>
              <p className="">이야기를 들려주세요.</p>
            </div>
          </div>
          {filter.keyword || filter.model || filter.tag ? (
            <div className="flex lg:block justify-center pt-5 lg:pt-0">
              <button
                type="button"
                className="btn btn-clear"
                onClick={() => onClear()}>
                초기화
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {showTag ? (
          <div className="search-filters-select lg:flex justify-between">
            <div className="search-filters-select-label mb-7 lg:mb-0 w-full lg:w-24 flex-none text-xs">
              JW 이노베이션
              <br className="hidden lg:block" />
              태그
            </div>
            <div className="w-full grow lg:pl-2">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                {jwTags.map((tag) => (
                  <div key={tag}>
                    <div
                      className={`btn btn-select w-auto ${
                        filterTag === tag ? 'close' : 'clear'
                      }`}
                      onClick={() =>
                        onHandleSelectTag(filterTag === tag ? '' : tag)
                      }>
                      {tag}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {showModel ? (
          <div className="search-filters-select lg:flex justify-between">
            <div className="search-filters-select-label mb-7 lg:mb-0 w-full lg:w-24 flex-none text-xs">
              자료 유형
            </div>
            <div className="w-full grow lg:pl-2">
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                {jwModels.map((model) => (
                  <div key={model.modelKey}>
                    <div
                      className={`btn btn-select w-auto ${
                        filterModel === model.modelKey ? 'close' : 'clear'
                      }`}
                      onClick={() =>
                        onHandleSelectModel(
                          filterModel === model.modelKey ? '' : model.modelKey
                        )
                      }>
                      {model.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <button
          type="button"
          className={`btn ${
            showMobile ? 'btn-close-white' : 'btn-ham'
          } w-6 h-6 absolute top-1/2 right-4 -mt-3 z-10 lg:hidden`}
          onClick={() => {
            setShowMobile(!showMobile)
            setShowTag(false)
            setShowModel(false)
          }}></button>
      </div>
    </div>
  )
}
