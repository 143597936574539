import { EpisodesItem } from '@/types'
import React from 'react'
import DateFormat from '../DateFormat'

export default function ModalContentsEpisode({ item }: { item: EpisodesItem }) {
  return item ? (
    <div className="modal-body-contents-inner">
      <div className="text-4xl leading-base">
        {item.data.title && item.data.title.KO ? (
          <div
            dangerouslySetInnerHTML={{
              __html: item.data.title.KO.replace(/\n/g, '<br />'),
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {/* <div className="mb-0.5 color-gray-alt">
        <DateFormat str={item.data.startDate.KO} />
        {item.data.endDate.KO ? '-' : ''}
        {item.data.endDate.KO ? <DateFormat str={item.data.endDate.KO} /> : ''}
      </div> */}
      {item.data.content && item.data.content.KO ? (
        <div className="mt-10">
          <div
            className="leading-lg text-lg font-eulyoo1945"
            dangerouslySetInnerHTML={{
              __html: item.data.content.KO.replace(/\n/g, '<br />'),
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {item.data.origin && item.data.origin.KO ? (
        <div className="mt-20 w-60">
          <p className="leading-lg">출처</p>
          <p
            className="leading-lg"
            dangerouslySetInnerHTML={{
              __html: item.data.origin.KO.replace(/\n/g, '<br />'),
            }}></p>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
