import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  HistoriesItem,
  EpisodesItem,
  ProductsItem,
  JwBuildingsType,
  vArchiveItem,
  RndMediaItem,
} from '@/types'
import ModalContentsHeader from './ModalContentsHeader'
import Tags from '../Tags'
import ModalContentsHistory from './ModalContentsHistory'
import ModalContentsEpisode from './ModalContentsEpisode'
import ModalContentsProduct from './ModalContentsProduct'
import ModalContentsFooter from './ModalContentsFooter'
import ModalContentsBuildingText from './ModalContentsBuildingText'
import Images from '../Images'
import { ModalContentsvArchive } from './ModalContentsvArchive'
import { ModalContentsRndMedia } from './ModalContentsRndMedia'
import { useSearchParams } from 'react-router-dom'
import { ModalContentsvArchiveIntro } from './ModalContentsvArchiveIntro'
import ImageSlider from '../ImageSlider'
import { customContentsList } from '@/configs'
import CustomContents1 from '../CustomContents/CustomContents_1'
import CustomContents2 from '../CustomContents/CustomContents_2'
import CustomContents3 from '../CustomContents/CustomContents_3'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

const bgColor = {
  histories: 'gray',
  episodes: 'light-green',
  products: 'light-blue',
  vArchive: 'light-green',
  rndMedia: 'light-gray-alt',
}

const bgColorIntro = 'gray-gradient'

export default function ModalContents({
  item,
  className,
}: {
  item:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | vArchiveItem
    | RndMediaItem
  className?: string
}) {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )

  const { previousUrl } = layoutState

  // State
  const [open, setOpen] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    document
      .getElementById('modal-contents-body-container')
      ?.addEventListener('scroll', setModalBody)

    // 모달 열기
    setOpen(true)
  }, [])

  /**
   * 본문 영역 크기 조절
   * @returns
   */
  const setModalBody = () => {
    const header = document.getElementById('modal-contents-header')
    const headerBg = document.getElementById('modal-contents-header-bg')
    const body = document.getElementById('modal-contents-body')
    const bodyContainer = document.getElementById(
      'modal-contents-body-container'
    )
    const footer = document.getElementById('modal-contents-footer')

    if (!header || !headerBg || !body || !bodyContainer || !footer) return

    const headerHeight = header.clientHeight
    const posMoveSize = header.clientHeight * (1 - 0.26)

    if (bodyContainer.scrollTop < posMoveSize) {
      const targetPos = Math.floor(headerHeight - bodyContainer.scrollTop)
      body.style.top = `${targetPos}px`
      // body.style.height = `calc(100% - ${targetPos}px - ${footer.clientHeight}px)`
      body.style.height = `calc(100% - ${targetPos}px)`

      headerBg.style.bottom = `${body.scrollTop}px`
    }
  }

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = (e) => {
    if (
      e.target.className === 'modal-container' ||
      e.target.className === 'modal-bg'
    ) {
      if (previousUrl) {
        navigate(previousUrl)
        dispatch(getPreviousUrl(''))
      } else {
        navigate(
          searchParams.get('scene') ? `?scene=${searchParams.get('scene')}` : ''
        )
      }
    }
  }

  /**
   * 모달 확장
   */
  const expandModal = () => {
    if (
      item?.data.contentsType === 'episodes' &&
      // @ts-ignore
      item?.data.era &&
      // @ts-ignore
      item?.data.era.KO
        ? 'has-era'
        : ''
    ) {
      document.getElementById('modal-contents')?.classList.add('expand')
    }
  }

  /**
   * 모달 축소
   */
  const collapseModal = () => {
    if (
      item?.data.contentsType === 'episodes' &&
      // @ts-ignore
      item?.data.era &&
      // @ts-ignore
      item?.data.era.KO
        ? 'has-era'
        : ''
    ) {
      document.getElementById('modal-contents')?.classList.remove('expand')
    }
  }

  /**
   * 모달 확대/축소
   */
  const toggleModal = () => {
    if (
      document.getElementById('modal-contents')?.classList.contains('expand')
    ) {
      collapseModal()
    } else {
      expandModal()
    }
  }

  return (
    <div
      id="modal-contents"
      className={`modal ${open ? 'open' : ''} ${className} modal-${
        item?.data.contentsType
      }${item?.data.code?.KO?.split('-')[2] === '01' ? 'intro' : ''} ${
        item?.data.contentsType === 'episodes' &&
        // @ts-ignore
        item?.data.era &&
        // @ts-ignore
        item?.data.era.KO
          ? 'has-era'
          : ''
      } ${customContentsList.indexOf(item.uid) >= 0 ? 'modal-custom' : ''}`}>
      {item ? (
        <>
          <div className="modal-bg" onClick={(e) => closeModal(e)}></div>
          <div className={`modal-container`} onClick={(e) => closeModal(e)}>
            <div
              className="modal-contents"
              style={{
                filter: `drop-shadow(8px 0px 12px rgba(0, 0, 0, 0.15))`,
                zIndex: 3200,
              }}>
              {item.data.contentsType === 'products' ? (
                <div
                  id="modal-contents-header"
                  className={`modal-header modal-header-${item.data.contentsType} bg-no-repeat bg-cover bg-center`}
                  style={{
                    backgroundImage: `url(${item.data.relationImages[0].file.path})`,
                  }}>
                  <div
                    id="modal-contents-header-bg"
                    className="modal-contents-header-bg"></div>
                </div>
              ) : (
                <div
                  id="modal-contents-header"
                  className={`modal-header modal-header-${item.data.contentsType}`}>
                  <ModalContentsHeader
                    item={item}
                    companyBuilding={
                      item.data.contentsType !== 'vArchive' &&
                      item.data.contentsType !== 'rndMedia'
                        ? // @ts-ignore
                          (item.data.companyBuilding[0].languageMap
                            .KO as JwBuildingsType)
                        : item.data.contentsType !== 'vArchive'
                        ? '과천'
                        : // @ts-ignore
                          (item.data.eraTag[0].languageMap
                            .KO as JwBuildingsType)
                    }
                  />
                </div>
              )}
              <div id="modal-contents-body" className={`modal-body `}>
                <div
                  className={`modal-body-fixed bg-${
                    item.data.contentsType === 'vArchive' &&
                    item.data.code?.KO?.split('-')[2] === '01'
                      ? bgColorIntro
                      : bgColor[item.data.contentsType]
                  } ${
                    item.data.contentsType === 'vArchive' &&
                    item.data.code?.KO?.split('-')[2] === '01'
                      ? 'narrow'
                      : ''
                  }`}
                  onMouseEnter={() => expandModal()}
                  onMouseLeave={() => collapseModal()}>
                  <div className="modal-body-fixed-contents py-2 px-2.5 border-b border-black">
                    {/* <ModalContentsBuildingText
                  companyBuilding={
                    item.data.contentsType !== 'vArchive' &&
                    item.data.contentsType !== 'rndMedia'
                      ? // @ts-ignore
                        (item.data.companyBuilding[0].languageMap.KO as JwBuildingsType)
                      : item.data.contentsType !== 'vArchive'
                      ? '과천'
                      : // @ts-ignore
                        (item.data.eraTag[0].KO as JwBuildingsType)
                  }
                /> */}
                    {item.data.contentsType === 'vArchive' &&
                    item.data.code?.KO?.split('-')[2] === '01' ? (
                      <></>
                    ) : (
                      <ModalContentsBuildingText
                        item={item}
                        companyBuilding={
                          item.data.contentsType !== 'vArchive' &&
                          item.data.contentsType !== 'rndMedia'
                            ? // @ts-ignore
                              (item.data.companyBuilding[0].languageMap
                                .KO as JwBuildingsType)
                            : item.data.contentsType !== 'vArchive'
                            ? '과천'
                            : // @ts-ignore
                              (item.data.eraTag[0].KO as JwBuildingsType)
                        }
                      />
                    )}
                  </div>
                </div>
                {item.data.contentsType === 'episodes' &&
                // @ts-ignore
                item.data.era &&
                // @ts-ignore
                item.data.era.KO ? (
                  <div
                    className={`modal-contents-era py-5 lg:py-6 pl-6 lg:pl-8 pr-16 lg:pr-8 bg-${
                      bgColor[item.data.contentsType]
                    }`}
                    onMouseEnter={() => expandModal()}
                    onMouseLeave={() => collapseModal()}>
                    <div className="">
                      <p className="leading-lg leading-6 pb-2 mb-2 border-b border-black">
                        시대상
                      </p>
                      <p
                        className="leading-lg"
                        dangerouslySetInnerHTML={{
                          // @ts-ignore
                          __html: item.data.era.KO.replace(/\n/g, '<br />'),
                        }}></p>
                    </div>
                    <div
                      className="absolute top-5 right-4 w-8 h-8"
                      onClick={() => toggleModal()}></div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  id="modal-contents-body-container"
                  className="modal-body-contents-container">
                  <div className="modal-body-contents">
                    {item.data.contentsType === 'histories' ? (
                      <ModalContentsHistory item={item as HistoriesItem} />
                    ) : (
                      <></>
                    )}
                    {item.data.contentsType === 'episodes' ? (
                      <>
                        <ModalContentsEpisode item={item as EpisodesItem} />
                        {item.data.relationMedias &&
                        item.data.relationMedias.length ? (
                          <ImageSlider images={item.data.relationMedias} />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {item.data.contentsType === 'products' ? (
                      <ModalContentsProduct item={item as ProductsItem} />
                    ) : (
                      <></>
                    )}
                    {item.data.contentsType === 'vArchive' &&
                    item.data.code?.KO?.split('-')[2] === '01' ? (
                      <ModalContentsvArchiveIntro item={item as vArchiveItem} />
                    ) : (
                      <></>
                    )}
                    {item.data.contentsType === 'vArchive' &&
                    item.data.code?.KO?.split('-')[2] !== '01' ? (
                      <>
                        <ModalContentsvArchive item={item as vArchiveItem} />
                        {customContentsList.indexOf(item.uid) >= 0 ? (
                          <div
                            id="custom-page"
                            className="custom-page md:w-1/2 md:h-full md:overflow-y-auto">
                            {customContentsList.indexOf(item.uid) === 0 ? (
                              <CustomContents1 />
                            ) : (
                              <></>
                            )}
                            {customContentsList.indexOf(item.uid) === 1 ? (
                              <CustomContents2 />
                            ) : (
                              <></>
                            )}
                            {customContentsList.indexOf(item.uid) === 2 ? (
                              <CustomContents3 />
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                        {item.data.relationMedias &&
                        item.data.relationMedias.length &&
                        customContentsList.indexOf(item.uid) === -1 ? (
                          <ImageSlider images={item.data.relationMedias} />
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {item.data.contentsType === 'rndMedia' ? (
                      <ModalContentsRndMedia item={item as RndMediaItem} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div id="modal-contents-footer" className="modal-footer">
                <ModalContentsFooter item={item} />
              </div>
            </div>
            {/* {item.data.relationImages &&
        item.data.relationImages.length &&
        item.data.contentsType !== 'histories' ? (
          <div className="modal-contents modal-contents-shadow">
            <div className="modal-body py-10 px-9">
              <ul className="space-y-20">
                <Images images={item.data.relationImages} />
              </ul>
            </div>
          </div>
        ) : (
          <></>
        )} */}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
