import React from 'react'

const MiniContents1 = () => {
  return (
    <div className="">
      <div className="md:pr-12">
        <h3 className="font-medium leading-lg mb-5">
          모든 것이 손으로 이루어졌던 작은 작업장
        </h3>
        <p className="leading-lg mb-10">
          충무로사옥은 공장과 사무실이 하나로 되어 있었다. 건평 100평가량 되는
          2층 건물이었고 공장 외관이 요즘 연립주택 구조와 비슷한 일자 형태로,
          2층은 사무실과 그 옆으로 여과실을 설치했고, 1층에 세척실, 증류실,
          멸균실 등 생산설비와 함께 1.5톤 용량의 조개탄 보일러 설비를 배치했다.{' '}
          <br />
          <br />
          당시 생산량 증대를 가로막는 가장 큰 요인은 작업 속도가 느릴 수 밖에
          없는 공장과 설비의 구조였다.
        </p>
      </div>

      <div className="space-y-3">
        <img
          src={'/assets/img-mini-1-1.png'}
          alt="서울 중구 충무로에 있었던 최초의 사옥의 모습"
          className="w-full block"
        />
        <p className="text-xs font-medium">
          서울 중구 충무로에 있었던 최초의 사옥의 모습
        </p>
      </div>
    </div>
  )
}

export default MiniContents1
