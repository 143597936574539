/**
 * Youtube ID 가져오기
 * @param url
 * @returns
 */
export const youtubeParser = (url) => {
  var regExp =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  var match = url.match(regExp)
  return match ? match[1] : false
}
