import { HistoriesGroup, JwBuildingsType } from '@/types'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import ModalHistoriseGroupContents from './ModalHistoriesGroupContents'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'
import { getPreviousUrl } from '@/states/actions/layout.actions'

const ModalHistoriesGroup = ({
  show,
  building,
  historiesGroup,
}: {
  show: boolean
  building: JwBuildingsType
  historiesGroup: HistoriesGroup[]
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )

  const { previousUrl } = layoutState

  // Effect
  useEffect(() => {
    const groupContainer = document.getElementById(
      'modal-histories-group-container'
    )
    const historiesItem = document.getElementById(
      `modal-histories-item-${searchParams.get('history')}`
    )

    if (groupContainer && historiesItem) {
      const offset = (window.innerWidth - historiesItem.clientWidth) * 0.5

      groupContainer.scrollTo({
        top: 0,
        left:
          groupContainer.scrollLeft +
          historiesItem.getBoundingClientRect().x -
          offset,
        behavior: 'smooth',
      })
    }

    setTimeout(() => {
      const currentHistoriesItem = document.querySelector(
        '.modal-histories-item.current'
      )
      const currentModalBody =
        currentHistoriesItem?.querySelector('.modal-body')
      const currentHistoryItem = document.getElementById(
        `modal-body-contents-${searchParams.get('uid')}`
      )
      const offset = currentHistoriesItem?.querySelector('.modal-body-fixed')
        ?.clientHeight as number

      if (currentModalBody && currentHistoryItem) {
        currentModalBody.scrollTo({
          top:
            currentModalBody.scrollTop + currentHistoryItem.offsetTop - offset,
          left: 0,
          behavior: 'smooth',
        })
      }
    }, 200)
  }, [searchParams.get('history')])

  /**
   * 모달 닫기
   * @param e
   */
  const closeModal = (e) => {
    if (
      e.target.className.includes('modal-container') ||
      e.target.className.includes('modal-histories-group-bg') ||
      e.target.className.includes('modal-histories-group-container')
    ) {
      if (previousUrl) {
        navigate(previousUrl)
        dispatch(getPreviousUrl(''))
      } else {
        navigate(
          searchParams.get('scene') ? `?scene=${searchParams.get('scene')}` : ''
        )
      }
    }
  }

  return (
    <div className={`modal-histories-group ${show ? 'show' : ''}`}>
      <div
        className="modal-histories-group-bg"
        onClick={(e) => closeModal(e)}></div>
      <div
        id="modal-histories-group-container"
        className="modal-histories-group-container"
        onClick={(e) => closeModal(e)}>
        <div className="modal-histories-group-contents">
          {historiesGroup.map((historyGroup, idx) => (
            <div
              id={`modal-histories-item-${idx}`}
              className={`modal-histories-item transition-all ${
                Number(searchParams.get('history')) === idx
                  ? 'current'
                  : 'inactive'
              }  ${
                Number(searchParams.get('history')) - 1 > idx
                  ? '-translate-x-full'
                  : ''
              }`}
              key={idx}>
              <ModalHistoriseGroupContents
                items={historyGroup.items}
                idx={idx}
                currentBuilding={building}
                historiesGroup={historiesGroup}
                len={historiesGroup.length}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ModalHistoriesGroup
