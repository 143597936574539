export enum ActionTypesLayout {
  TOGGLE_NAV = 'TOGGLE_NAV', // Nav 설정
  TOGGLE_ARCHIVE = 'TOGGLE_ARCHIVE', // 아카이브 여닫기
  TOGGLE_GUIDE = 'TOGGLE_GUIDE', // 가이드 여닫기
  TOGGLE_BG = 'TOGGLE_BG', // 배경음악 끄기/켜기
  GET_PREVIOUS_URL = 'GET_PREVIOUS_URL', // 이전 URL 가져오기
}

export type ActionsLayout =
  | LayoutToggleGnb
  | LayoutToggleArchive
  | LayoutToggleGuide
  | LayoutToggleBg
  | LayoutGetPreviousUrl

export interface LayoutToggleGnb {
  type: ActionTypesLayout.TOGGLE_NAV
  payload: {
    nav: boolean
  }
}
export interface LayoutToggleArchive {
  type: ActionTypesLayout.TOGGLE_ARCHIVE
  payload: {
    archive: boolean
  }
}
export interface LayoutToggleGuide {
  type: ActionTypesLayout.TOGGLE_GUIDE
  payload: {
    guide: boolean
  }
}
export interface LayoutToggleBg {
  type: ActionTypesLayout.TOGGLE_BG
  payload: {
    bg: boolean
  }
}

export interface LayoutGetPreviousUrl {
  type: ActionTypesLayout.GET_PREVIOUS_URL
  payload: {
    url: string
  }
}
