const MiniContents3 = () => {
  return (
    <div className="">
      <div className="pr-12">
        <h3 className="font-medium leading-lg mb-5">
          흩어져있던 것이 하나로 뭉친 JW타워
        </h3>
        <p className="leading-lg mb-10">
          서울 서초구 서초동 1424-2번지(남부순환로 2477)에 들어선 JW타워는 지하
          5층, 지상 14층 규모로 조성되었다. <br />
          <br />
          1층은 로비와 카페가 자리잡았고, 2층에서 7층까지는 임대로 타회사가
          사용했다. JW는 8층부터 14층, 지하 1,2층을 사용했는데, 8층은 그룹 통합
          회의센터, 9층부터는 JW홀딩스, JW중외제약을 비롯해 그 동안 흩어져있던
          JW신약, JW생명과학, JW메디칼, JW바이오사이언스가 들어와 함께 근무했다.{' '}
          <br />
          <br />
          JW타워 지하 1층에는 JW중외제약의 신약연구센터, 제제연구센터,
          원료연구센터 등 연구소가 자리잡고 있었는데, 대부분의 제약사 연구소가
          서울 근교에 있었던 것과 비교하면 이례적이었다.
        </p>
      </div>
      <div className="space-y-10">
        <div>
          <img
            src={'/assets/img-mini-6-1.png'}
            alt="서초구 남부순환로에 자리잡은 JW타워의 모습"
            className="w-full block"
          />
        </div>
        <div className="space-y-3">
          <img
            src={'/assets/img-mini-6-2.png'}
            alt="서초구 남부순환로에 자리잡은 JW타워의 모습"
            className="w-full block"
          />
          <p className="text-xs font-medium">
            서초구 남부순환로에 자리잡은 JW타워의 모습
          </p>
        </div>
      </div>
    </div>
  )
}

export default MiniContents3
