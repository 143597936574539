import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'

const CustomContents1 = () => {
  // State (Redux)
  const { contentsState } = useSelector(
    (state: RootState) => ({
      contentsState: state.contents,
    }),
    shallowEqual
  )
  const { loaded } = contentsState

  // Effect
  useEffect(() => {
    // AOS.init({})
  })

  // Effect
  /* useEffect(() => {
    if (loaded) {
      initAos()

      document
        .querySelector('.modal-body-contents')
        ?.addEventListener('scroll', initAos)

      document
        .querySelector('#custom-page')
        ?.addEventListener('scroll', initAos)
    }
  }, [loaded])

  const initAos = () => {
    AOS.init({})
  } */

  return (
    <div className="py-8 px-8 space-y-60 pb-40 md:pb-0 border-t border-black md:border-none">
      <div className="custom-page-1-1 space-y-8">
        <img
          src={'/assets/img-custom-1-1.png'}
          alt={
            '유리병에 증류수와 포도당을 기준량으로 넣고 의약용 활성탄을 섞은 다음에 이병을 수조에 넣고 70까지 가열한다.'
          }
        />
        <div className="grid grid-cols-2">
          <p className="leading-lg">
            유리병에 증류수와 포도당을 기준량으로 넣고 의약용 활성탄을 섞은
            다음에 이병을 수조에 넣고 70까지 가열한다.
          </p>
        </div>
      </div>
      <div className="custom-page-1-2 grid grid-cols-2 gap-8">
        <img
          src={'/assets/img-custom-1-2.png'}
          alt={
            '충무로 사옥의 구조상 1층에 보일러실이 있고 2층에 여과작업실이 있어서 가열한 유리병을 2층까지 손수 날라야 했다'
          }
        />
        <p className="leading-lg">
          충무로 사옥의 구조상 1층에 보일러실이 있고 2층에 여과작업실이 있어서
          가열한 유리병을 2층까지 손수 날라야 했다.
        </p>
      </div>
      <div className="custom-page-1-3 grid grid-cols-2 gap-8">
        <p className="leading-lg">
          9ℓ 로 제작한 내열성이 있는 유리병에 깔때기를 올리고 그 위에 부채꼴
          여과지를 올려 놓는다. 여과용 유리병의 여과지 위로 가열한 포도당 용액을
          천천히 흘려 보낸다. 여과지를 통해 걸러진 이 용액이 ‘5%포도당’
          수액제이다.
        </p>
        <img
          src={'/assets/img-custom-1-3.png'}
          alt={
            '9ℓ 로 제작한 내열성이 있는 유리병에 깔때기를 올리고 그 위에 부채꼴 여과지를 올려 놓는다. 여과용 유리병의 여과지 위로 가열한 포도당 용액을 천천히 흘려 보낸다. 여과지를 통해 걸러진 이 용액이 ‘5%포도당’ 수액제이다.'
          }
        />
      </div>
      <div className="custom-page-1-4 grid grid-cols-2 gap-8">
        <img
          src={'/assets/img-custom-1-4.png'}
          alt={
            '포도당 용액이 담긴 이 유리병을 100촉짜리 전등에 대고 육안으로 일일이 이물질을 검사한 뒤에 충전과 멸균의 공정으로 넘깁니다.'
          }
          data-aos-delay="0"
        />
        <p className="leading-lg">
          포도당 용액이 담긴 이 유리병을 100촉짜리 전등에 대고 육안으로 일일이
          이물질을 검사한 뒤에 충전과 멸균의 공정으로 넘깁니다.
        </p>
      </div>
      <div className="custom-page-1-5 gap-8">
        <div className="grid grid-cols-2">
          <p className="leading-lg pl-8" data-aos-delay="0">
            여과된 포도당이 든 유리병을 다시 1층 보일러실로 가져와서 121 ℃까지
            가열하여 멸균의 과정을 거쳐서 비로소 수액이 완성되었다.
          </p>
        </div>
        <img
          src={'/assets/img-custom-1-5.png'}
          alt={
            '여과된 포도당이 든 유리병을 다시 1층 보일러실로 가져와서 121 ℃까지 가열하여 멸균의 과정을 거쳐서 비로소 수액이 완성되었다.'
          }
          data-aos-delay="0"
        />
      </div>
    </div>
  )
}

export default CustomContents1
