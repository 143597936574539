import { MediaInterface } from '@/types'
import { youtubeParser } from '@/utils'
import React from 'react'
import { YoutubePlayer } from './YoutubePlayer'

export const Youtubes = ({
  videos,
  noCaption,
  inactive,
}: {
  videos: MediaInterface[]
  noCaption?: boolean
  inactive?: boolean
}) => {
  return (
    <>
      {videos.map((video) => (
        <li key={video._id}>
          {inactive ? <></> : <YoutubePlayer item={video} />}

          {video && video.languageMap && video.languageMap.KO ? (
            <p className={`pt-3 leading-lg pr-32`}>
              {video.languageMap.KO.name as string}
            </p>
          ) : (
            <></>
          )}
        </li>
      ))}
    </>
  )
}
