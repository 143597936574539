import React from 'react'
import {
  HistoriesItem,
  EpisodesItem,
  ProductsItem,
  ResourcesItem,
  JwBuildingsType,
  vArchiveItem,
  RndMediaItem,
} from '@/types'

export default function ModalContentsBuildingText({
  item,
  companyBuilding,
}: {
  companyBuilding: JwBuildingsType
  item:
    | HistoriesItem
    | EpisodesItem
    | ProductsItem
    | ResourcesItem
    | vArchiveItem
    | RndMediaItem
}) {
  return (
    <div className="marquee">
      {item.data.contentsType !== 'products' ? (
        <>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
          <span>{item.data.title.KO}</span>
        </>
      ) : (
        <></>
      )}
      {item.data.contentsType === 'products' ? (
        <>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameKor.KO
            }
          </span>
          <span>
            {
              // @ts-ignore
              item.data.nameEng.KO
            }
          </span>
        </>
      ) : (
        <></>
      )}
      {/* {companyBuilding === '충무로' ? (
        <>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
          <span>희망을 담은 창조의 연못</span>
        </>
      ) : (
        <></>
      )}
      {companyBuilding === '하월곡' ? (
        <>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
          <span>정공법으로 타개한 위기, 정상궤도를 향해 나아가다</span>
        </>
      ) : (
        <></>
      )}
      {companyBuilding === '신대방' ? (
        <>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
          <span>글로벌 헬스케어 기업을 향한 인프라를 구축하다</span>
        </>
      ) : (
        <></>
      )}
      {companyBuilding === '서초' ? (
        <>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
          <span>R&D 경쟁력을 바탕으로 글로벌 시장 공략에 시동을 걸다</span>
        </>
      ) : (
        <></>
      )}
      {companyBuilding === '과천' ? (
        <>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
          <span>과천, 새로운 역사의 시작</span>
        </>
      ) : (
        <></>
      )} */}
    </div>
  )
}
