import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@/states/store'
import ReactGA from 'react-ga'

import Home from './pages/Home'
import Search from './pages/Search'
import Vr from './pages/Vr'
import NotFound from './pages/NotFound'

if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID as string)
}

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const setNavigator = () => {
  const body = document.body
  body.classList.add(
    navigator.platform && navigator.platform.includes('Win') ? 'win' : 'non-win'
  )
}
setNavigator()

function App() {
  useEffect(() => {
    var tag = document.createElement('script')

    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/vr/:vr" element={<Vr />} />
          <Route index path="/search" element={<Search />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
